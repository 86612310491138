import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PieChartIcon from '@mui/icons-material/PieChart';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledIconButton,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Emission } from '../../models/Emission';

const { DateTime } = require("luxon");

type Props = {};

export const EmissionSettings: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);
  const [emissions, setEmissions] = useState<Emission[]>([]);
  const [emissionsCount, setEmissionsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("created_at DESC");

  const [asnModuleActive, setAsnModuleActive] = useState<number>(0);
  const [prefix, setPrefix] = useState<number>(100);
  const [amount, setAmount] = useState<number>(20000);

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onGenerate = (id: number) => {
    fetch_one<Emission>('/nl/v3/objects/custom_action', {
      object: 'emission',
      class_action: 'custom_api_generate_asn',
      handler_id: login?.id,
      id: id,
      prefix: prefix,
      amount: amount
    }, (obs, complete_data) => {
      onSearch('');
      setPrefix(100);
      setAmount(20000);
      setAsnModuleActive(0);
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Emission>('/nl/v3/objects/fetch_all', {
      object: 'emission',
      fields: [
        'id', 'created_at', 'start_date', 'end_date', 'budget', 'enabled', 'spent_budget', 'free_key_count', 'waiting_lists|count'
      ],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setEmissions(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setEmissionsCount(count);

      setLoader(false);
    }, (z) => {

    }, login)
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'start_date', headerName: 'Start Date', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'budget', headerName: 'Budget', width: 160, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${params.row.budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`}
      </Typography>
    </> },
    { field: 'spent_budget', headerName: 'Spent', width: 160, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${parseInt((params.row.spent_budget || 0).toString(), 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} EUR`}
      </Typography>
    </> },
    { field: 'spent_budget_percent', headerName: 'Spent %', width: 100, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem'}}>
        {`${((params.row.spent_budget / params.row.budget) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}%`}
      </Typography>
    </> },
    { field: 'free_key_count', headerName: 'Open keys', width: 100, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
        setAsnModuleActive(params.row.id);
      }}>
        {`${params.row.free_key_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
      </Typography>
    </> },
    { field: 'waiting_lists_count', headerName: 'WaitingList', width: 100, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
        navigate(`/emission/${params.row.id}/waiting_list`);
      }}>
        {`${params.row.waiting_lists_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
      </Typography>
    </> },
  ];

  return (
    <Page sx={{}} title="Emission Settings" helper="emissions" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/emission/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {asnModuleActive > 0 && <Grid container spacing="8">
        <Grid item xs={6}>
          <StyledTextField
            label="Prefix"
            value={(prefix || 100).toString()}
            id='prefix'
            onChange={(v) => {
              setPrefix(!!v ? parseInt(v, 10) : 100);
            }}
            fullWidth
            error={prefix < 100 || prefix > 999}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            label="Amount of keys"
            value={(amount || 20000).toString()}
            id='amount'
            onChange={(v) => {
              setAmount(!!v ? parseInt(v, 10) : 100);
            }}
            fullWidth
            error={amount < 1}
          />
        </Grid>
        <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Generate ASNs"
            id='save'
            disabled={amount < 1 || prefix < 100 || prefix > 999}
            contained
            saveStartIcon
            onClick={(v) => {
              onGenerate(asnModuleActive);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              setAsnModuleActive(0);
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>}
      {asnModuleActive < 1 && <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Open Detail" color="primary" path={`/emission/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            <StyledIconButton title="Stats" color="primary" path={`/emission/${params.row.id}/stats`}>
              <PieChartIcon />
            </StyledIconButton>
          </>);
        }}
        objectCount={emissionsCount}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(emissions || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />}
    </Page>
  );
}
