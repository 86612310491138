import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one } from '../lib/server_helper';

import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import { loginAtom } from '../lib/auth';

import CachedIcon from '@mui/icons-material/Cached';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconItsme from "../images/itsme.svg";
import LaunchIcon from '@mui/icons-material/Launch';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { AlertCheckInputs } from '../components/alerts/CheckInputs';
import { AlertSaved } from '../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../components/alerts/SomethingWentWrong';

import {
  BankAccount,
  NotarySearch,
  Page,
  StyledButton,
  StyledDateField,
  StyledDropzone,
  StyledIconButton,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  Typography,
  UserSearch
} from '../components/interface';
import { DetailLine } from '../components/interface/Detail/DetailLine';
import { UserDetailBase } from '../components/user_detail/Base';

import { Death } from '../models/Death';
import { Notary } from '../models/Notary';
import { User } from '../models/User';

const { DateTime } = require("luxon");

type UserDetailProps = {};

export const UserDetail: FunctionComponent<UserDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const userId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [oldUserDetail, setOldUserDetail] = useState<User|null>(null);
  const [userDetail, setUserDetail] = useState<User|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [deathing, setDeathing] = useState<boolean>(false);
  const [officeQuits, setOfficeQuits] = useState<boolean>(false);
  const [exiting, setExiting] = useState<boolean>(false);
  const [authEditing, setAuthEditing] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [deathDetail, setDeathDetail] = useState<Death|null>(null);
  const [reporterVisible, setReporterVisible] = useState<boolean>(false);
  const [selectedNotary, setSelectedNotary] = useState<Notary|null>(null);
  const [selectedSpouse, setSelectedSpouse] = useState<User|null>(null);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [officeDeathDate, setOfficeDeathDate] = useState<string>(DateTime.now().toFormat("D"));

  const [item, setItem] = useState<File>();
  const [deathItem, setDeathItem] = useState<File>();
  const [guardianItem, setGuardianItem] = useState<File>();

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'first_name', 'last_name', 'gender', 'username', 'email', 'email_code', 'birth_date', 'readable_address', 'cor_street', 'cor_street_2',
    'cor_number', 'cor_zip', 'cor_city', 'phone', 'mobile', 'zicht_account', 'locale', 'vennoot_number', 'enabled', 'created_at',
    'stock_number', 'transactions|first|stock_saldo_value', 'vennoot_account', 'mainframe_id', 'confirmed', 'last_activity',
    'last_import', 'last_updated', 'itsme_sub', 'street', 'number', 'zip', 'city', 'document_list', 'has_guardianship',
    'guardian|first_name/last_name/email/birth_date/phone/comment/get_identity_front/street/number/zip/city', 'get_identity_front',
    'identity_front_blob|content_type', 'guardian|identity_front_blob|content_type', 'email_history', 'identity_front|filename/byte_size',
    'guardian|identity_front|filename/byte_size', 'mfa_status', 'sec_q_status', 'open_stock_transfer_count',
    'transactions|first|transaction_date', 'user|transactions|first|transaction_date', 'death_reports|count', 'mail_enabled', 'commercial_mail_enabled',
    'gdpr_personal_data', 'emission_keys', 'impersonate_code', 'impersonate_created_at'
  ];

  const fetchUser = () => {
    setLoader(true);
    
    fetch_one<User>('/nl/v3/objects/fetch_all', {
      object: 'user',
      fields: fields,
      sub_fields: {
        'emission_keys': ['id', 'modded_asn_key', 'emission|id/start_date']
      },
      id: userId
    }, (ob, complete_data) => {
      setUserDetail(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login)
  };

  const FormData_append_object = (fd:FormData, obj:any, key:string) => {
    var i:string, k:string;
    for(i in obj) {
      k = key ? key + '[' + i + ']' : i;
      if(typeof obj[i] == 'object')
        FormData_append_object(fd, obj[i], k);
      else
        fd.append(k, obj[i]);
    }
  }

  const createExit = () => {
    fetch_one<Death>('/nl/v3/objects/custom_action', {
      object: 'user',
      class_action: 'custom_api_blank_exit',
      id: userDetail?.id,
      handler_id: login?.id
    }, (obs, complete_data) => {
      navigate(obs.custom_result?.downloadable || "/exits");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  }

  const deleteConversion = () => {
    fetch_one<Death>('/nl/v3/objects/custom_action', {
      object: 'user',
      class_action: 'custom_api_undo_2020conversion',
      id: userDetail?.id,
      handler_id: login?.id
    }, (obs, complete_data) => {
      fetchUser();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  }

  const generateBlankTransfer = () => {
    if (!!login) {
      fetch_one<User>('/nl/v3/objects/custom_action', {
        object: 'user',
        class_action: 'custom_api_blank_overdracht',
        handler_id: login.id,
        id: userDetail?.id
      }, (obs, complete_data) => {
        if (!!obs && !!obs?.custom_result && !!obs.custom_result?.downloadable) window.open(obs.custom_result?.downloadable, "_BLANK");
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  const generateFiscal = (brief:number) => {
    if (!!login) {
      fetch_one<User>('/nl/v3/objects/custom_action', {
        object: 'user',
        class_action: 'custom_api_generate_fiscal',
        handler_id: login.id,
        id: userDetail?.id,
        brief: brief
      }, (obs, complete_data) => {
        if (!!obs && !!obs?.custom_result && !!obs.custom_result?.downloadable) window.open(obs.custom_result?.downloadable, "_BLANK");
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  const generateImpersonate = () => {
    fetch_one<User>('/nl/v3/objects/custom_action', {
      object: 'user',
      class_action: 'custom_api_generate_impersonate',
      // fields: fields,
      id: userDetail?.id,
      handler_id: login?.id
    }, (ob, complete_data) => {
      fetchUser();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  }

  const generate_register = () => {
    fetch_one<User>('/nl/v3/objects/custom_action', {
      object: 'user',
      class_action: 'custom_api_generate_register',
      // fields: fields,
      id: userDetail?.id,
      handler_id: login?.id
    }, (ob, complete_data) => {
      fetchUser();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  }

  const saveDeath = () => {
    setSaveLoader(true);

    let formData = new FormData();
    formData.append('object', 'death');
    formData.append('handler_id', login?.id?.toString() || '');
    fields.map(field => {
      formData.append('fields[]', field);
    });
    if (!!deathItem) formData.append('item', deathItem);
    FormData_append_object(formData, deathDetail, 'ob');
    // formData.append('ob[first_name]', userDetail?.first_name || '');
    
    fetch_one<Death>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      setSaveLoader(false);
      setDeathing(false);
      fetchUser();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const saveOfficeQuitting = () => {
    setSaveLoader(true);

    fetch_one<Death>('/nl/v3/objects/custom_action', {
      object: 'user',
      class_action: 'custom_api_office_quits',
      id: userDetail?.id,
      handler_id: login?.id,
      date: officeDeathDate
    }, (obs, complete_data) => {
      setSaveLoader(false);
      setOfficeQuits(false);
      fetchUser();
      if (!!obs && !!obs?.custom_result && !!obs.custom_result?.downloadable) window.open(obs.custom_result?.downloadable, "_BLANK");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  }

  const saveUser = (partial_object:boolean|{sq1_id?: string, sq2_id?: string, sq3_id?: string, sq1_answer?: string, sq2_answer?: string, sq3_answer?: string, mfa_status?: string}) => {
    setErrors([]);
    let errors = [];

    if (!partial_object) {
      if (typeof(userDetail?.first_name) === 'undefined' || userDetail?.first_name === null || userDetail?.first_name.trim() === "") errors.push('first_name');
      if (typeof(userDetail?.last_name) === 'undefined' || userDetail?.last_name === null || userDetail?.last_name.trim() === "") errors.push('last_name');
      if (typeof(userDetail?.birth_date) === 'undefined' || userDetail?.birth_date === null || userDetail?.birth_date.trim() === "") errors.push('birth_date');
      // if (typeof(userDetail?.email) === 'undefined' || userDetail?.email === null || userDetail?.email.trim() === "") errors.push('email');
      if (typeof(userDetail?.street) === 'undefined' || userDetail?.street === null || userDetail?.street.trim() === "") errors.push('street');
      if (typeof(userDetail?.number) === 'undefined' || userDetail?.number === null || userDetail?.number.trim() === "") errors.push('number');
      if (typeof(userDetail?.zip) === 'undefined' || userDetail?.zip === null || userDetail?.zip.trim() === "") errors.push('zip');
      if (typeof(userDetail?.city) === 'undefined' || userDetail?.city === null || userDetail?.city.trim() === "") errors.push('city');
      // if ((typeof(userDetail?.phone) === 'undefined' || userDetail?.phone === null || userDetail?.phone.trim() === "") && (typeof(userDetail?.mobile) === 'undefined' || userDetail?.mobile === null || userDetail?.mobile.trim() === "")) {
      //   errors.push('phone')
      //   errors.push('mobile')
      // };
      if (typeof(userDetail?.zicht_account) === 'undefined' || userDetail?.zicht_account === null || userDetail?.zicht_account.trim() === "") errors.push('zicht_account');
      if (!!userDetail?.has_guardianship) {
        if (typeof(userDetail?.guardian_first_name) === 'undefined' || userDetail?.guardian_first_name === null || userDetail?.guardian_first_name.trim() === "") errors.push('guardian_first_name');
        if (typeof(userDetail?.guardian_last_name) === 'undefined' || userDetail?.guardian_last_name === null || userDetail?.guardian_last_name.trim() === "") errors.push('guardian_last_name');
        if (typeof(userDetail?.guardian_email) === 'undefined' || userDetail?.guardian_email === null || userDetail?.guardian_email.trim() === "") errors.push('guardian_email');
        if (typeof(userDetail?.guardian_birth_date) === 'undefined' || userDetail?.guardian_birth_date === null || userDetail?.guardian_birth_date.trim() === "") errors.push('guardian_birth_date');
        if (typeof(userDetail?.guardian_street) === 'undefined' || userDetail?.guardian_street === null || userDetail?.guardian_street.trim() === "") errors.push('guardian_street');
        if (typeof(userDetail?.guardian_number) === 'undefined' || userDetail?.guardian_number === null || userDetail?.guardian_number.trim() === "") errors.push('guardian_number');
        if (typeof(userDetail?.guardian_zip) === 'undefined' || userDetail?.guardian_zip === null || userDetail?.guardian_zip.trim() === "") errors.push('guardian_zip');
        if (typeof(userDetail?.guardian_city) === 'undefined' || userDetail?.guardian_city === null || userDetail?.guardian_city.trim() === "") errors.push('guardian_city');
        if (typeof(userDetail?.guardian_phone) === 'undefined' || userDetail?.guardian_phone === null || userDetail?.guardian_phone.trim() === "") errors.push('guardian_phone');
      }
    } else {

    }
    
    if (errors.length < 1) {
      setLoader(true);
  
      let formData = new FormData();
      formData.append('object', 'user');
      fields.map(field => {
        formData.append('fields[]', field);
      });
      if (!!userId) formData.append('ob[id]', userId.toString());

      if (!partial_object) {
        formData.append('ob[first_name]', userDetail?.first_name || '');
        formData.append('ob[last_name]', userDetail?.last_name || '');
        formData.append('ob[birth_date]', userDetail?.birth_date || '');
        formData.append('ob[enabled]', userDetail?.enabled ? '1' : '0');
        
        formData.append('ob[gender]', userDetail?.gender || 'M');
        formData.append('ob[email]', userDetail?.email || '');
        formData.append('ob[street]', userDetail?.street || '');
        formData.append('ob[number]', userDetail?.number || '');
        formData.append('ob[zip]', userDetail?.zip || '');
        formData.append('ob[city]', userDetail?.city || '');
        formData.append('ob[phone]', userDetail?.phone || '');
        formData.append('ob[mobile]', userDetail?.mobile || '');
        formData.append('ob[cor_street]', userDetail?.cor_street || '');
        formData.append('ob[cor_street_2]', userDetail?.cor_street_2 || '');
        formData.append('ob[cor_number]', userDetail?.cor_number || '');
        formData.append('ob[cor_zip]', userDetail?.cor_zip || '');
        formData.append('ob[cor_city]', userDetail?.cor_city || '');

        formData.append('ob[mail_enabled]', userDetail?.mail_enabled ? '1' : '0');
        formData.append('ob[commercial_mail_enabled]', userDetail?.commercial_mail_enabled ? '1' : '0');

        if (!!userDetail?.has_guardianship && !!guardianItem) formData.append('guardian[identity_front]', guardianItem);

        if (authEditing) {
          formData.append('ob[username]', userDetail?.username || '');
          if (!!userDetail?.password) formData.append('ob[password]', userDetail?.password || '');
        }
        formData.append('ob[locale]', userDetail?.locale || 'nl');
        formData.append('ob[confirmed]', userDetail?.confirmed ? '1' : '0');

        formData.append('ob[zicht_account]', userDetail?.zicht_account || '');

        if (!!item) formData.append('ob[identity_front]', item);

        if (!!userDetail?.has_guardianship) {
          formData.append('guardian[first_name]', userDetail?.guardian_first_name || '');
          formData.append('guardian[last_name]', userDetail?.guardian_last_name || '');
          formData.append('guardian[email]', userDetail?.guardian_email || '');
          formData.append('guardian[birth_date]', userDetail?.guardian_birth_date || '');
          formData.append('guardian[street]', userDetail?.guardian_street || '');
          formData.append('guardian[number]', userDetail?.guardian_number || '');
          formData.append('guardian[zip]', userDetail?.guardian_zip || '');
          formData.append('guardian[city]', userDetail?.guardian_city || '');
          formData.append('guardian[phone]', userDetail?.guardian_phone || '');
          formData.append('guardian[comment]', userDetail?.guardian_comment || '');
        }
      } else {
        if (typeof(partial_object) === 'object') {
          if (typeof(partial_object.mfa_status) === 'undefined') {
            formData.append('ob[sq1_id]', partial_object.sq1_id || '');
            formData.append('ob[sq2_id]', partial_object.sq2_id || '');
            formData.append('ob[sq3_id]', partial_object.sq3_id || '');
            formData.append('ob[sq1_answer]', partial_object.sq1_answer || '');
            formData.append('ob[sq2_answer]', partial_object.sq2_answer || '');
            formData.append('ob[sq3_answer]', partial_object.sq3_answer || '');
          } else {
            formData.append('ob[mfa_status]', partial_object.mfa_status || '0');
          }
        }
      }
      
      fetch_one<User>('/nl/v3/objects/save', formData, (ob, complete_data) => {
        setUserDetail(ob);
        setItem(undefined);
        setGuardianItem(undefined);
        setErrors([]);
        setAuthEditing(false);
        setEditing(false);
        setUserSavedSnackOpen(true);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, login);
    } else {
      setErrorSnackOpen(true);
      setErrors(errors);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  let has_email_history = !!userDetail?.email_history && userDetail.email_history.length > 0;

  return (
    <Page sx={{}} title={`${!!userDetail && [userDetail.first_name, userDetail.last_name, `(${userDetail.vennoot_number})`].join(" ")}`}>
      <Grid container spacing="8">
        <Grid container item xs={12} md={8} spacing="8" sx={{alignContent: 'baseline'}}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}

          {!!editing && <>
            <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="Identity"
                adornment={!!userDetail && !!userDetail.itsme_sub ? <Avatar src={IconItsme} sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} /> : <></>}
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="First Name"
                    value={userDetail?.first_name || ''}
                    id='first_name'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, first_name: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Last Name"
                    value={userDetail?.last_name || ''}
                    id='last_name'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, last_name: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledDateField
                    label="Birthdate"
                    value={userDetail?.birth_date || ''}
                    id='birth_date'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, birth_date: v.toString()});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    label="Is Active"
                    value={!!userDetail?.enabled}
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, enabled: !!v});}}
                  />
                </Grid>
              </UserDetailBase>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.contact
                }}
                title="Contact"
                wide
              >
                <Grid item xs={12}>
                  <StyledSelect
                    label="Gender"
                    value={userDetail?.gender || 'M'}
                    id='gender'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, gender: v});}}
                    list={[
                      {id: 'M', name: "Male"},
                      {id: 'F', name: "Female"}
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="E-mail"
                    value={userDetail?.email || ''}
                    id='email'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, email: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Street"
                    value={userDetail?.street || ''}
                    id='street'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, street: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Number"
                    value={userDetail?.number || ''}
                    id='number'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, number: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Zip"
                    value={userDetail?.zip || ''}
                    id='zip'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, zip: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="City"
                    value={userDetail?.city || ''}
                    id='city'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, city: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Country"
                    value={userDetail?.country || ''}
                    id='country'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, country: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Phone"
                    value={userDetail?.phone || ''}
                    id='phone'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, phone: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Mobile"
                    value={userDetail?.mobile || ''}
                    id='mobile'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, mobile: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="COR Street header"
                    value={userDetail?.cor_street_2 || ''}
                    id='cor_street_2'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, cor_street_2: v});}}
                    fullWidth
                    capitalaction
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="COR Street"
                    value={userDetail?.cor_street || ''}
                    id='cor_street'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, cor_street: v});}}
                    fullWidth
                    capitalaction
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="COR Number"
                    value={userDetail?.cor_number || ''}
                    id='cor_number'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, cor_number: v});}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="COR Zip"
                    value={userDetail?.cor_zip || ''}
                    id='cor_zip'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, cor_zip: v});}}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="COR City"
                    value={userDetail?.cor_city || ''}
                    id='cor_city'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, cor_city: v});}}
                    fullWidth
                    capitalaction
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    label="Receives emails (main switch)"
                    value={!!userDetail?.mail_enabled}
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, mail_enabled: !!v});}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    label="Receives commercial emails"
                    value={!!userDetail?.commercial_mail_enabled}
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, commercial_mail_enabled: !!v});}}
                  />
                </Grid>
              </UserDetailBase>

              {!!userDetail?.has_guardianship && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.guardian
                }}
                title="Guardian ID"
                wide
              >
                <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                  <StyledDropzone
                    acceptedFiles={{
                      'image/*': [],
                      'application/pdf': []
                    }}
                    onDrop={acceptedFiles => {setGuardianItem(acceptedFiles[0]);}}
                    multiple={false}
                    maxFiles={1}
                    maxSize={3 * 1000 * 1000}
                    items={!!guardianItem ? [guardianItem] : []}
                    dropText="Click here, or drop some files."
                    dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
                  />
                  {!!userDetail?.guardian_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {userDetail?.guardian_identity_front_filename} ({(parseInt(userDetail?.guardian_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                </Grid>
              </UserDetailBase>}

            </Grid>
            <Grid container item xs={12} md={6} rowSpacing="8" sx={{alignContent: 'baseline'}}>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.account
                }}
                title="Account"
                wide
              >
                {authEditing && <Grid item xs={12}>
                  <StyledTextField
                    label="Username"
                    value={userDetail?.username || ''}
                    id='username'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, username: v});}}
                    fullWidth
                  />
                </Grid>}
                {authEditing && <Grid item xs={12}>
                  <StyledTextField
                    label="Password"
                    value={userDetail?.password || ''}
                    id='password'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, password: v});}}
                    fullWidth
                  />
                </Grid>}
                {!authEditing && <Grid item xs={12}>
                  <StyledButton
                    label="Edit username/password"
                    contained
                    id='edit_auth'
                    onClick={(v) => {
                      setAuthEditing(true);
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>}
                <Grid item xs={12}>
                  <StyledSelect
                    label="Language"
                    value={userDetail?.locale || 'nl'}
                    id='locale'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, locale: v});}}
                    list={[
                      {id: 'nl', name: "Dutch"},
                      {id: 'fr', name: "French"}
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    label="Confirmed"
                    value={!!userDetail?.confirmed}
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, confirmed: !!v});}}
                  />
                </Grid>
              </UserDetailBase>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.register
                }}
                title="Register"
                wide
              >
                <Grid item xs={12}>
                  <BankAccount
                    label="Zicht account"
                    value={userDetail?.zicht_account || ''}
                    id="zicht_account"
                    onChange={(v, checked) => {if (!!userDetail) setUserDetail({...userDetail, zicht_account: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
              </UserDetailBase>

              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="ID"
                wide
              >
                <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                  <StyledDropzone
                    acceptedFiles={{
                      'image/*': [],
                      'application/pdf': []
                    }}
                    onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
                    multiple={false}
                    maxFiles={1}
                    maxSize={3 * 1000 * 1000}
                    items={!!item ? [item] : []}
                    dropText="Click here, or drop some files."
                    dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
                  />
                  {!!userDetail?.identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {userDetail?.identity_front_filename} ({(parseInt(userDetail?.identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
                </Grid>
              </UserDetailBase>

              {!!userDetail?.has_guardianship && <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.guardian
                }}
                title="Guardian"
                wide
              >
                <Grid item xs={12}>
                  <StyledTextField
                    label="Guardian first name"
                    value={userDetail?.guardian_first_name || ''}
                    id='guardian_first_name'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_first_name: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Guardian last name"
                    value={userDetail?.guardian_last_name || ''}
                    id='guardian_last_name'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_last_name: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Guardian e-mail"
                    value={userDetail?.guardian_email || ''}
                    id='guardian_email'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_email: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledDateField
                    label="Guardian birthdate"
                    value={userDetail?.guardian_birth_date || ''}
                    id='guardian_birth_date'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_birth_date: v.toString()});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Guardian street"
                    value={userDetail?.guardian_street || ''}
                    id='guardian_street'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_street: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Guardian number"
                    value={userDetail?.guardian_number || ''}
                    id='guardian_number'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_number: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label="Guardian zip"
                    value={userDetail?.guardian_zip || ''}
                    id='guardian_zip'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_zip: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Guardian city"
                    value={userDetail?.guardian_city || ''}
                    id='guardian_city'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_city: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Guardian phone"
                    value={userDetail?.guardian_phone || ''}
                    id='guardian_phone'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_phone: v});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Guardian comment"
                    value={userDetail?.guardian_comment || ''}
                    id='guardian_comment'
                    onChange={(v) => {if (!!userDetail) setUserDetail({...userDetail, guardian_comment: v});}}
                    fullWidth
                    multiline
                  />
                </Grid>
              </UserDetailBase>}

            </Grid>

          </>}

          {!deathing && !exiting && !editing && !officeQuits && <>
            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="Identity"
              adornment={!!userDetail && !!userDetail.itsme_sub ? <Avatar src={IconItsme} sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} /> : <></>}
            >
              <DetailLine name="First name" value={!!userDetail ? userDetail.first_name : ''} />
              <DetailLine name="Last name" value={!!userDetail ? userDetail.last_name : ''} />
              <DetailLine name="Birthdate" date={!!userDetail ? userDetail?.birth_date : ''} />
              <DetailLine copyable name="Vennoot Number" value={!!userDetail ? userDetail.vennoot_number : ''} />
              <DetailLine no_divider name="Is Active" bool={!!userDetail ? userDetail.enabled : false} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.register
              }}
              title="Register"
            >
              <DetailLine name="Stock Number" value={!!userDetail ? userDetail.stock_number?.toString() : ''} />
              <DetailLine name="Type" value={!!userDetail ? (userDetail.vennoot_number ? userDetail.vennoot_number[0] : '') : ''} />
              <DetailLine name="Value" value={!!userDetail ? `${userDetail.transactions_first_stock_saldo_value?.toFixed(0)} EUR` : ''} />
              <DetailLine no_divider copyable name="Zicht account" value={!!userDetail ? userDetail.zicht_account : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.account
              }}
              title="Account"
            >
              <DetailLine name="Username" value={!!userDetail ? userDetail.username : ''} />
              <DetailLine name="EmailCode" value={!!userDetail ? userDetail.email_code : ''} />
              <DetailLine name="Language" value={!!userDetail ? userDetail.locale : ''} />
              <DetailLine no_divider={!userDetail?.impersonate_code} name="Confirmed" bool={!!userDetail ? userDetail.confirmed : false} />
              {!!userDetail?.impersonate_code && <DetailLine copyable name="ImpCode" value={`https://www.argenco.be/impersonate/${userDetail.impersonate_code}`} />}
              {!!userDetail?.impersonate_code && <DetailLine no_divider name="ImpCode Created" time_ago={userDetail.impersonate_created_at} />}
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.internal
              }}
              title="Internal"
            >
              <DetailLine name="Mainframe ID" value={!!userDetail ? userDetail.mainframe_id : ''} />
              <DetailLine name="Vennoot Account" value={!!userDetail ? userDetail.vennoot_account : ''} />
              <DetailLine name="Last Activity" time_ago={!!userDetail ? userDetail.last_activity : ''} />
              <DetailLine name="Created" datetime={!!userDetail ? userDetail.created_at : ''} />
              <DetailLine name="Last change (us)" time_ago={!!userDetail ? userDetail.last_import : ''} />
              <DetailLine no_divider name="Last change (vennoot)" time_ago={!!userDetail ? userDetail.last_updated : ''} />
            </UserDetailBase>

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.contact
              }}
              title="Contact"
              wide
            >
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Gender" gender_icons value={!!userDetail ? userDetail.gender : ''} />
                <DetailLine copyable name="E-mail" value={!!userDetail ? userDetail.email : ''} />
                <DetailLine name="Receives emails" bool={!!userDetail ? userDetail.mail_enabled : false} />
                <DetailLine no_divider name="Address" value={!!userDetail ? [userDetail.street, userDetail.number].join(" ") : ''} />
                <DetailLine no_divider name="" value={!!userDetail ? [userDetail.zip, userDetail.city].join(" ") : ''} />
              </Grid>
              <Grid item container xs={6} sx={{alignContent: 'baseline'}} rowSpacing="4">
                <DetailLine name="Phone" value={!!userDetail ? userDetail.phone : ''} />
                <DetailLine name="Mobile" value={!!userDetail ? userDetail.mobile : ''} />
                <DetailLine name="Commercial emails" bool={!!userDetail ? userDetail.commercial_mail_enabled : false} />
                {!!userDetail?.cor_street && !!userDetail?.cor_zip && <DetailLine no_divider name="COR Address" value={!!userDetail ? [userDetail.cor_street_2].join(" ") : ''} />}
                {!!userDetail?.cor_street && !!userDetail?.cor_zip && <DetailLine no_divider name="" value={!!userDetail ? [userDetail.cor_street, userDetail.cor_number].join(" ") : ''} />}
                {!!userDetail?.cor_street && !!userDetail?.cor_zip && <DetailLine no_divider name="" value={!!userDetail ? [userDetail.cor_zip, userDetail.cor_city].join(" ") : ''} />}
              </Grid>
            </UserDetailBase>

            {!!userDetail?.has_guardianship && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.guardian
              }}
              title="Guardian"
            >
              <DetailLine name="First name" value={!!userDetail ? userDetail.guardian_first_name : ''} />
              <DetailLine name="Last name" value={!!userDetail ? userDetail.guardian_last_name : ''} />
              <DetailLine copyable name="E-mail" value={!!userDetail ? userDetail.guardian_email : ''} />
              <DetailLine name="Birthdate" date={!!userDetail ? userDetail?.guardian_birth_date : ''} />
              <DetailLine no_divider name="Address" value={!!userDetail ? [userDetail.guardian_street, userDetail.guardian_number].join(" ") : ''} />
              <DetailLine name="" value={!!userDetail ? [userDetail.guardian_zip, userDetail.guardian_city].join(" ") : ''} />
              <DetailLine name="Phone" value={!!userDetail ? userDetail.guardian_phone : ''} />
              <DetailLine no_divider name="Comment" value={!!userDetail ? userDetail.guardian_comment : ''} />
            </UserDetailBase>}

            {!!userDetail?.has_guardianship && !has_email_history && <Grid item xs={12} md={6}>
              &nbsp;
            </Grid>}

            {(!!userDetail?.email_history && userDetail.email_history.length > 0) && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.email_history
              }}
              title={`Email History (${userDetail.email_history.length})`}
              wide
            >
              {[...userDetail.email_history].reverse().slice(0, 3).map(hist => (<>
                <Grid container item xs={6}>
                  <DetailLine no_divider name="From" value={hist.old} />
                </Grid>
                <Grid container item xs={6} sx={{paddingLeft: 1}}>
                  <DetailLine no_divider name="To" value={hist.new} />
                </Grid>
                <Grid container item xs={12}>
                  <DetailLine name="On" time_ago={hist.on} />
                </Grid>
              </>))}
            </UserDetailBase>}

            <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.security
              }}
              title="Security"
            >
              <DetailLine name="Security questions" bool={!!userDetail ? userDetail.sec_q_status : false} />
              {!userDetail?.mfa_status && <DetailLine no_divider name="Two step verification" bool={false} />}
              {!!userDetail?.mfa_status && <DetailLine no_divider name="Two step verification" value={!!userDetail ? (userDetail.mfa_status === 0 ? "OFF" : (userDetail.mfa_status === 1 ? "Auth" : "SMS")) : ''} />}
            </UserDetailBase>

            {(userDetail?.emission_keys || []).length > 0 && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.emission
              }}
              title="Emission"
            >
              {(userDetail?.emission_keys || []).map(eKey => <DetailLine 
                name={DateTime.fromISO(eKey.emission_start_date).toFormat("LLLL yyyy")}
                value={eKey.modded_asn_key}  
                launchable={`/emission/checklist/${eKey.id}`}
              />)}
            </UserDetailBase>}

            {!!userDetail?.get_identity_front && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.identity
              }}
              title="ID"
              adornment={!!userDetail && !!userDetail.get_identity_front && <>
                <StyledIconButton
                  path={userDetail.get_identity_front}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  window.open(userDetail.get_identity_front, "_BLANK");
                }} /> */}
              </>}
            >
              <Grid item xs={12}>
                {!!userDetail.identity_front_blob_content_type && userDetail.identity_front_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={userDetail.get_identity_front} />}
                {!!userDetail.identity_front_blob_content_type && userDetail.identity_front_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={userDetail.get_identity_front} />}
              </Grid>
              <Grid item xs={12}>
                {!!userDetail?.identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {userDetail?.identity_front_filename} ({(parseInt(userDetail?.identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

            {!!userDetail?.has_guardianship && userDetail.guardian_get_identity_front && <UserDetailBase
              sx={{
                backgroundColor: theme.palette.profile_blox?.guardian
              }}
              title="Guardian ID"
              adornment={!!userDetail && !!userDetail.guardian_get_identity_front && <>
                <StyledIconButton
                  path={userDetail.guardian_get_identity_front}
                  newTab
                  sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24}} 
                ><LaunchIcon /></StyledIconButton>
                {/* <LaunchIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={(e) => {
                  e.preventDefault();
                  window.open(userDetail.guardian_get_identity_front, "_BLANK");
                }} /> */}
              </>}
            >
              <Grid item xs={12}>
                {!!userDetail.guardian_identity_front_blob_content_type && userDetail.guardian_identity_front_blob_content_type.indexOf("image") > -1 && <img style={{maxWidth: '100%'}} src={userDetail.guardian_get_identity_front} />}
                {!!userDetail.guardian_identity_front_blob_content_type && userDetail.guardian_identity_front_blob_content_type.indexOf("pdf") > -1 && <iframe style={{width: '100%', height: '300px'}} src={userDetail.guardian_get_identity_front} />}
              </Grid>
              <Grid item xs={12}>
                {!!userDetail?.guardian_identity_front_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {userDetail?.guardian_identity_front_filename} ({(parseInt(userDetail?.guardian_identity_front_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
              </Grid>
            </UserDetailBase>}

          </>}

          {!!deathing && <Grid container item xs={12}>
            {!!userDetail?.vennoot_number?.startsWith("B") && <Grid item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.exit
                }}
                title="Death Certificate"
                wide
              >
                <Grid item xs={12}>
                  <Typography sx={{fontSize: '0.75rem'}}>Bijlage</Typography>
                  <StyledDropzone
                    acceptedFiles={{
                      'image/*': [],
                      'application/pdf': []
                    }}
                    onDrop={acceptedFiles => {setDeathItem(acceptedFiles[0]);}}
                    multiple={false}
                    maxFiles={1}
                    maxSize={3 * 1000 * 1000}
                    items={!!deathItem ? [deathItem] : []}
                    dropText="Click here, or drop some files."
                    dropHint="Allowed: 1 image (.png, .jpeg, .jpg, .gif) or PDF (.pdf), < 3mb"
                  />

                  <Grid item xs={12} sx={{marginTop: 1}}>
                    <StyledTextField
                      label="Handler Note"
                      value={deathDetail?.extra_data?.handler_note || ''}
                      id='handler_note'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({
                        ...deathDetail,
                        extra_data: {
                          ...(deathDetail?.extra_data || {}),
                          handler_note: v
                        }
                      });}}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </UserDetailBase>
            </Grid>}

            <Grid item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.guardian
                }}
                title={`Reporter: <span style={{fontWeight: 'bold'}}>${['Heir', 'Office', 'Notary', 'Other'][deathDetail?.reporter_data?.mode || 0]}</span>`}
                wide
                adornment={<>
                  {!reporterVisible && <ExpandMoreIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={() => {
                    setReporterVisible(!reporterVisible);
                  }} />}
                  {!!reporterVisible && <ExpandLessIcon sx={{position: 'absolute', top: 0.5, right: 0.5, width: 24, height: 24, cursor: 'pointer'}} onClick={() => {
                    setReporterVisible(!reporterVisible);
                  }} />}
                </>}
              >
                <Grid item xs={12}>
                  {!!reporterVisible && <>
                    {((deathDetail?.reporter_data?.mode || 0) === 0 || (deathDetail?.reporter_data?.mode || 0) === 3) && <Grid container item xs={12}>
                      <DetailLine name="First name" value={deathDetail?.reporter_data?.first_name || ''} />
                      <DetailLine name="Last name" value={deathDetail?.reporter_data?.last_name || ''} />
                      <DetailLine name="Phone" value={deathDetail?.reporter_data?.phone || ''} />
                      <DetailLine name="Mobile" value={deathDetail?.reporter_data?.mobile || ''} />
                      <DetailLine copyable name="Email" value={deathDetail?.email || ''} />
                      <DetailLine no_divider name="Address" value={!!deathDetail ? [deathDetail?.reporter_data?.street, deathDetail?.reporter_data?.number].join(" ") : ''} />
                      <DetailLine no_divider name="" value={!!deathDetail ? [deathDetail?.reporter_data?.zip, deathDetail?.reporter_data?.city].join(" ") : ''} />
                    </Grid>}
                    {(deathDetail?.reporter_data?.mode || 0) === 1 && <>
                      <DetailLine name="Employee" value={deathDetail?.reporter_data?.employee_name || ''} />
                      <DetailLine name="Office" value={deathDetail?.reporter_data?.office_number?.toString() || ''} />
                      <DetailLine name="Phone" value={deathDetail?.office_phone || ''} />
                      <DetailLine copyable no_divider name="Email" value={deathDetail?.office_email || ''} />
                    </>}
                    {(deathDetail?.reporter_data?.mode || 0) === 2 && <Grid container item xs={12}>
                      <DetailLine name="Employee" value={deathDetail?.reporter_data?.employee_name || ''} />
                      <DetailLine name="Office" value={deathDetail?.reporter_data?.office_name || ''} />
                      <DetailLine name="Zip" value={deathDetail?.reporter_data?.zip || ''} />
                      <DetailLine name="Phone" value={deathDetail?.reporter_data?.phone || ''} />
                      <DetailLine copyable name="Email" value={deathDetail?.email || ''} />
                    </Grid>}
                    <Grid container item xs={12} sx={{marginTop: 1}}>
                      <Grid item xs={12}>
                        <StyledTextField
                          label="E-mail"
                          value={deathDetail?.reporter_data?.email || ''}
                          id='email'
                          onChange={(v) => {if (!!deathDetail) setDeathDetail({
                            ...deathDetail,
                            reporter_data: {
                              ...(deathDetail?.reporter_data || {}),
                              email: v
                            }
                          });}}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </>}
                </Grid>
              </UserDetailBase>
            </Grid>

            <Grid item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.identity
                }}
                title="Deceased"
                wide
              >
                <Grid item xs={6}>
                  <StyledTextField
                    label="First name"
                    value={deathDetail?.user_first_name || userDetail?.first_name || ''}
                    id='first_name'
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, first_name: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextField
                    label="Last name"
                    value={deathDetail?.user_last_name || userDetail?.last_name || ''}
                    id='last_name'
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, last_name: v});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledDateField
                    label="Birth date"
                    value={deathDetail?.birth_date || userDetail?.birth_date || ''}
                    id='birth_date'
                    onChange={(v) => {}}
                    fullWidth
                    errorArray={errors}
                    disabled
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Birth place"
                    value={deathDetail?.deceased_data?.birth_place || ''}
                    id='birth_place'
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), birth_place: v}});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledDateField
                    label="Death date"
                    value={deathDetail?.deceased_data?.death_date || ''}
                    id='death_date'
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), death_date: v.toString()}});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label="Death place"
                    value={deathDetail?.deceased_data?.death_place || ''}
                    id='death_place'
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), death_place: v}});}}
                    fullWidth
                    capitalaction
                    errorArray={errors}
                  />
                </Grid>
              </UserDetailBase>
            </Grid>

            <Grid item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.email_history
                }}
                title="Marital status"
                wide
              >
                <Grid item xs={4}>
                  <StyledButton
                    label="Not married"
                    id='married_0'
                    contained={(deathDetail?.deceased_data?.marital_status || '0') === '0'}
                    // secondary={(deathDetail?.deceased_data?.marital_status || '0') !== '0'}
                    onClick={(v) => {
                      if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), marital_status: '0'}});
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledButton
                    label="Married"
                    id='married_1'
                    contained={(deathDetail?.deceased_data?.marital_status || '0') === '1'}
                    // secondary={(deathDetail?.deceased_data?.marital_status || '0') !== '1'}
                    onClick={(v) => {
                      if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), marital_status: '1'}});
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledButton
                    label="Widowed"
                    id='married_2'
                    contained={(deathDetail?.deceased_data?.marital_status || '0') === '2'}
                    // secondary={(deathDetail?.deceased_data?.marital_status || '0') !== '2'}
                    onClick={(v) => {
                      if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), marital_status: '2'}});
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    label="Spouse name"
                    value={deathDetail?.deceased_data?.spouse_name || ''}
                    id='spouse_name'
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, deceased_data: {...(deathDetail?.deceased_data || {}), spouse_name: v}});}}
                    fullWidth
                    errorArray={errors}
                  />
                </Grid>
                {!selectedSpouse && <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5}}>
                  <UserSearch
                    initialValues={{street: userDetail?.street, number: userDetail?.number, zip: userDetail?.zip}}
                    customUserLayout={["padding", "birthdate", "address"]}
                    fields={['id', 'first_name', 'last_name', 'vennoot_number', 'birth_date']}
                    resultLayout={(user) => <Box sx={{
                      padding: 1,
                      cursor: 'pointer',
                      border: '1px solid transparent',
                      borderColor: theme.palette.primary.main,
                      marginBottom: 0.5
                    }} onClick={() => {
                      setSelectedSpouse(user);
                      if (!!deathDetail) setDeathDetail({...deathDetail, spouse_id: user.id, deceased_data: {...(deathDetail?.deceased_data || {}), spouse_name: [user.first_name, user.last_name].join(' ')}});
                    }}>
                      <Typography sx={{fontWeight: 'bold'}}>{user.first_name} {user.last_name} - {DateTime.fromISO(user.birth_date).toFormat("dd/LL/yyyy")}</Typography>
                      <Typography>{user.street} {user.number}, {user.zip} {user.city}</Typography>
                    </Box>}
                  />
                </Grid>}
                {!!selectedSpouse && <Grid item xs={12}>
                  <Box sx={{
                    // width: '100%',
                    padding: 1,
                    border: '1px solid transparent',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: '#98B6B4',
                    marginBottom: 0.5,
                    position: 'relative'
                  }}>
                    <Typography sx={{fontWeight: 'bold'}}>{selectedSpouse.first_name} {selectedSpouse.last_name} ({selectedSpouse.vennoot_number}) - {DateTime.fromISO(selectedSpouse.birth_date).toFormat("dd/LL/yyyy")}</Typography>
                    <Typography>{selectedSpouse.street} {selectedSpouse.number}, {selectedSpouse.zip} {selectedSpouse.city}</Typography>
                    <LinkOffIcon sx={{position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: theme.palette.secondary.main}} onClick={() => {setSelectedSpouse(null); if (!!deathDetail) setDeathDetail({...deathDetail, spouse_id: undefined, deceased_data: {...(deathDetail?.deceased_data || {}), spouse_name: ''}});}} />
                  </Box>
                </Grid>}
              </UserDetailBase>
            </Grid>

            <Grid item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.internal
                }}
                title="Notary"
                wide
              >
                <Grid item xs={6}>
                  <StyledButton
                    label="No notary"
                    id='notary_0'
                    contained={(deathDetail?.notary_data?.status?.toString() || '0') === '0'}
                    // secondary={(deathDetail?.notary_data?.status || '0') !== '0'}
                    onClick={(v) => {
                      if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), status: '0'}});
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledButton
                    label="Notary provided"
                    id='notary_1'
                    contained={(deathDetail?.notary_data?.status?.toString() || '0') === '1'}
                    // secondary={(deathDetail?.notary_data?.status || '0') !== '1'}
                    onClick={(v) => {
                      if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), status: '1'}});
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                {deathDetail?.notary_data?.status?.toString() === '1' && <>
                  {!!selectedNotary && <Grid item xs={12} sx={{marginTop: 1}}>
                    <Box sx={{
                      // width: '100%',
                      padding: 1,
                      border: '1px solid transparent',
                      borderColor: theme.palette.primary.main,
                      backgroundColor: '#98B6B4',
                      marginBottom: 0.5,
                      position: 'relative'
                    }}>
                      <Typography sx={{fontStyle: 'italic', ...(selectedNotary.not_imported ? {textDecoration: 'line-through'} : {})}}>{selectedNotary.first_name} {selectedNotary.last_name} [{selectedNotary.number}]</Typography>
                      <Typography sx={{fontWeight: 'bold'}}>{selectedNotary.office_name} [{selectedNotary.office_number}]</Typography>
                      <Typography>{selectedNotary.street}, {selectedNotary.zip} {selectedNotary.city}</Typography>
                      <LinkOffIcon sx={{position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: theme.palette.secondary.main}} onClick={() => {setSelectedNotary(null); if (!!deathDetail) setDeathDetail({...deathDetail, notary_id: undefined, notary_data: {...(deathDetail?.notary_data || {}), email: ''}});}} />
                    </Box>
                  </Grid>}
                  {!selectedNotary && <Grid item xs={12} sx={{paddingRight: 0.5, backgroundColor: '#98B6B4', marginLeft: 0.5, marginTop: 1}}>
                    <NotarySearch
                      initialValues={{catch_all: (deathDetail?.notary_data?.name || '')}}
                      fields={['id', 'office_name', 'office_number', 'email', 'street', 'number', 'zip', 'city', 'not_imported', 'first_name', 'last_name']}
                      resultLayout={(notary) => <Box sx={{
                        padding: 1,
                        cursor: 'pointer',
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                        marginBottom: 0.5
                      }} onClick={() => {
                        setSelectedNotary(notary);
                        if (!!deathDetail) setDeathDetail({...deathDetail, notary_id: notary.id, notary_data: {...(deathDetail?.notary_data || {}), email: notary.email, locale: notary.language}});
                      }}>
                        <Typography sx={{fontStyle: 'italic', ...(notary.not_imported ? {textDecoration: 'line-through'} : {})}}>{notary.first_name} {notary.last_name} [{notary.number}]</Typography>
                        <Typography sx={{fontWeight: 'bold'}}>{notary.office_name} [{notary.office_number}]</Typography>
                        <Typography>{notary.street}, {notary.zip} {notary.city}</Typography>
                      </Box>}
                    />
                  </Grid>}
                  {!selectedNotary && <Grid item xs={12}>
                    <StyledTextField
                      label="Name"
                      value={deathDetail?.notary_data?.name || ''}
                      id='notary_name'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), name: v}});}}
                      fullWidth
                      capitalaction
                      errorArray={errors}
                    />
                  </Grid>}
                  <Grid item xs={12}>
                    <StyledTextField
                      label="Email"
                      value={deathDetail?.notary_data?.email || ''}
                      id='notary_email'
                      onChange={(v) => {if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), email: v}});}}
                      fullWidth
                      errorArray={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="NL"
                      id='notary_nl'
                      contained={(deathDetail?.notary_data?.locale || 'nl') === 'nl'}
                      // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'nl'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), locale: 'nl'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="FR"
                      id='notary_fr'
                      contained={(deathDetail?.notary_data?.locale || 'nl') === 'fr'}
                      // secondary={(deathDetail?.notary_data?.locale || 'nl') !== 'fr'}
                      onClick={(v) => {
                        if (!!deathDetail) setDeathDetail({...deathDetail, notary_data: {...(deathDetail?.notary_data || {}), locale: 'fr'}});
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                </>}
              </UserDetailBase>
            </Grid>

            {/* <Grid container item xs={12} sx={{marginTop: 2}}>
              <UserDetailBase
                sx={{
                  backgroundColor: theme.palette.profile_blox?.register
                }}
                title="Confirmables"
                wide
              >
                <Grid item xs={12}>
                  <StyledSwitch
                    label="Finalisation"
                    offLabel="Do not finish"
                    onLabel="Complete"
                    value={deathDetail?.extra_data?.confirmable?.checked === '1'}
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({
                      ...deathDetail,
                      status: !!v ? 2 : 1,
                      extra_data: {
                        ...(deathDetail.extra_data || {}),
                        checked: (!!v ? '1' : '0'),
                        confirmable: {
                          ...deathDetail.extra_data?.confirmable || {},
                          checked: (!!v ? '1' : '0')
                        }
                      }
                    });}}
                  />
                  <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Create a transaction, and disable the user if it results in having 0 stock.</Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={6}>
                  <StyledSwitch
                    label="Add letter to download queue"
                    readonly={deathDetail?.extra_data?.confirmable?.checked === '0'}
                    offLabel="No"
                    onLabel="Yes"
                    value={deathDetail?.extra_data?.confirmable?.download === '1'}
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({
                      ...deathDetail,
                      extra_data: {
                        ...(deathDetail.extra_data || {}),
                        confirmable: {
                          ...deathDetail.extra_data?.confirmable || {},
                          download: (!!v ? '1' : '0')
                        }
                      }
                    });}}
                  />
                  <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Letter version of list201 in the user's language.</Typography>
                </Grid>
                <Grid item xs={6}>
                  <StyledSwitch
                    label="Add to PAIN (queued)"
                    readonly={deathDetail?.extra_data?.confirmable?.checked === '0'}
                    offLabel="No"
                    onLabel="Yes"
                    value={deathDetail?.extra_data?.confirmable?.pain === '1'}
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({
                      ...deathDetail,
                      extra_data: {
                        ...(deathDetail.extra_data || {}),
                        confirmable: {
                          ...deathDetail.extra_data?.confirmable || {},
                          pain: (!!v ? '1' : '0')
                        }
                      }
                    });}}
                  />
                  <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>99.9% of the cases this should be 'yes'.</Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={6}>
                  <StyledSwitch
                    label="Add to FOD list (queued)"
                    readonly={deathDetail?.extra_data?.confirmable?.checked === '0'}
                    offLabel="No"
                    onLabel="Yes"
                    value={deathDetail?.extra_data?.confirmable?.fod === '1'}
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({
                      ...deathDetail,
                      extra_data: {
                        ...(deathDetail.extra_data || {}),
                        confirmable: {
                          ...deathDetail.extra_data?.confirmable || {},
                          fod: (!!v ? '1' : '0')
                        }
                      }
                    });}}
                  />
                  <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem', color: 'grey'}}>Add to today's FOD list queue.</Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    label={`Send email to reporter (${deathDetail?.reporter_data?.email || '-'})`}
                    readonly={deathDetail?.extra_data?.confirmable?.checked === '0' || !deathDetail?.reporter_data?.email}
                    onLabel="Send"
                    offLabel="Do not send"
                    value={deathDetail?.extra_data?.confirmable?.notify_email === '1'}
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({
                      ...deathDetail,
                      extra_data: {
                        ...(deathDetail.extra_data || {}),
                        confirmable: {
                          ...deathDetail.extra_data?.confirmable || {},
                          notify_email: (!!v ? '1' : '0')
                        }
                      }
                    });}}
                  />
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                  <StyledSwitch
                    label={`Send email to notary (${deathDetail?.notary_data?.email || '-'})`}
                    readonly={deathDetail?.extra_data?.confirmable?.checked === '0' || !deathDetail?.notary_data?.email}
                    onLabel="Send"
                    offLabel="Do not send"
                    value={deathDetail?.extra_data?.confirmable?.notary_email === '1'}
                    onChange={(v) => {if (!!deathDetail) setDeathDetail({
                      ...deathDetail,
                      extra_data: {
                        ...(deathDetail.extra_data || {}),
                        confirmable: {
                          ...deathDetail.extra_data?.confirmable || {},
                          notary_email: (!!v ? '1' : '0')
                        }
                      }
                    });}}
                  />
                </Grid>
              </UserDetailBase>
            </Grid> */}

            <Grid container item xs={12} sx={{marginTop: 2}}>
              {!!userDetail?.open_stock_transfer_count && <Box sx={{
                // width: '100%',
                border: '1px solid transparent',
                borderColor: theme.palette.secondary.main,
                padding: 1,
                borderRadius: 1,
                backgroundColor: theme.palette.secondary.light,
                marginBottom: 1
              }}>
                <Typography>There are {userDetail?.open_stock_transfer_count} open transfers for this user!</Typography>
              </Box>}

              {DateTime.fromISO(userDetail?.transactions_first_transaction_date) > DateTime.fromISO(deathDetail?.deceased_data?.death_date) && <Box sx={{
                // width: '100%',
                border: '1px solid transparent',
                borderColor: theme.palette.secondary.main,
                padding: 1,
                borderRadius: 1,
                backgroundColor: theme.palette.secondary.light,
                marginBottom: 1
              }}>
                <Typography>There are existing transactions AFTER the death date!</Typography>
              </Box>}

              {DateTime.fromISO("2020-08-28") > DateTime.fromISO(deathDetail?.deceased_data?.death_date) && <Box sx={{
                // width: '100%',
                border: '1px solid transparent',
                borderColor: theme.palette.secondary.main,
                padding: 1,
                borderRadius: 1,
                backgroundColor: theme.palette.secondary.light,
                marginBottom: 1
              }}>
                <Typography>This user died before the 2020 conversion!</Typography>
              </Box>}
            </Grid>
          </Grid>}

          {!!exiting && <>
            exit stuff - should not be visible!
          </>}

          {!!officeQuits && <>
            <Grid item xs={4}>
              <StyledDateField
                label="Date of transaction"
                value={officeDeathDate}
                id='transaction_date'
                onChange={(v) => {setOfficeDeathDate(v.toString());}}
                fullWidth
              />
            </Grid>
          </>}
        </Grid>
        <Grid container item xs={12} md={4} spacing="8" sx={{alignContent: 'baseline', flexBasis: "calc(33.33% + 8px) !important"}} rowSpacing="4">
          <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              // borderColor: theme.palette.primary.main,
              borderRadius: 1,
              padding: 1
            }}>
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Actions</Typography>
                </Grid>

                {!!officeQuits && <Grid item xs={12}>
                  {!!saveLoader && <CircularProgress />}
                  {!saveLoader && <StyledButton
                    label="Save"
                    id='save'
                    contained
                    disabled={!officeDeathDate}
                    onClick={(v) => {
                      saveOfficeQuitting();
                    }}
                  />}
                </Grid>}
                {!!officeQuits && <Grid item xs={12}>
                  <Typography sx={{color: theme.palette.secondary.main, fontStyle: 'italic', fontSize: '0.8rem'}}>Saving will immediately approve the office quitting, and will download the PAIN. <Box sx={{color: theme.palette.tertiary?.main, textDecoration: 'underline', cursor: 'pointer', display: 'inline'}} onClick={() => {
                    setOfficeQuits(false);
                  }}>Shit, I don't want this</Box></Typography>
                </Grid>}

                {!!deathing && <Grid item xs={6}>
                  {!!saveLoader && <CircularProgress />}
                  {!saveLoader && <StyledButton
                    label="Save"
                    id='save'
                    contained
                    disabled={!deathItem}
                    onClick={(v) => {
                      saveDeath();
                    }}
                    sx={{width: '100%'}}
                  />}
                </Grid>}
                {!!deathing && <Grid item xs={6}>
                  {!saveLoader && <StyledButton
                    label="Back"
                    id='back'
                    onClick={(v) => {
                      setDeathing(false);
                    }}
                    sx={{width: '100%'}}
                  />}
                </Grid>}

                {editing && <>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Update"
                      id='update'
                      contained
                      saveStartIcon
                      onClick={(v) => {
                        saveUser(false);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Cancel"
                      id='cancel'
                      onClick={(v) => {
                        setUserDetail(oldUserDetail);
                        setOldUserDetail(null);
                        setItem(undefined);
                        setGuardianItem(undefined);
                        setErrors([]);
                        setAuthEditing(false);
                        setEditing(false);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                </>}

                {!editing && !exiting && !deathing && !officeQuits && <>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Edit"
                      id='edit'
                      contained
                      onClick={(v) => {
                        setOldUserDetail(userDetail);
                        setEditing(true);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {(userDetail?.death_reports_count || 0) < 1 && userDetail?.vennoot_number?.startsWith("A") && <Grid item xs={6}>
                    <StyledButton
                      label="Onmid. Uittreding"
                      id='uittreding'
                      contained
                      onClick={(v) => {
                        setOfficeQuits(true);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}
                  {(userDetail?.death_reports_count || 0) < 1 && <Grid item xs={6}>
                    <StyledButton
                      label="Uittreding"
                      id='uittreding'
                      contained
                      onClick={(v) => {
                        createExit();
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}
                  {(userDetail?.death_reports_count || 0) < 1 && userDetail?.vennoot_number?.startsWith("B") && <Grid item xs={6}>
                    <StyledButton
                      label="Deceased"
                      id='deceased'
                      contained
                      onClick={(v) => {
                        let d:Death = {
                          user_id: userDetail?.id,
                          first_name: userDetail.first_name || '',
                          last_name: userDetail.last_name || '',
                          birth_date: userDetail.birth_date || undefined,
                          user_first_name: userDetail.first_name || '',
                          user_last_name: userDetail.last_name || '',
                          user_vennoot_number: userDetail.vennoot_number || '',
                          user_locale: userDetail.locale || '',
                          email: login?.email,
                          office_email: login?.email,
                          status: 1,
                          original_status: 1,
                          reporter_data: {
                            mode: 1,
                            employee_name: login?.first_name,
                            office_number: 9999,
                            phone: '',
                            email: login?.email
                          },
                          deceased_data: {
                            birth_place: '',
                            death_date: '',
                            death_place: ''
                          }
                        };

                        setDeathDetail(d);
                        setDeathing(true);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}

                  {userDetail?.vennoot_number?.startsWith("B") && login?.id === 7 && !!userDetail?.transactions_first_transaction_date && DateTime.fromISO(userDetail?.transactions_first_transaction_date).year === 2020 && <Grid item xs={6}>
                    <StyledButton
                      label="Pre2020 conv. del."
                      id='conv_del'
                      // contained
                      onClick={(v) => {
                        if (window.confirm("This will delete a transaction, and reset the user as it was before the conversion of August 2020. Continue?")) {
                          deleteConversion();
                        }
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledButton
                      label="GDPR"
                      id='gdpr'
                      onClick={(v) => {
                        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(userDetail?.gdpr_personal_data));
                        var dlAnchorElem = document.getElementById('downloadAnchorElem');
                        if (!!dlAnchorElem) {
                          dlAnchorElem.setAttribute("href", dataStr);
                          dlAnchorElem.setAttribute("download", "gdpr_data.json");
                          dlAnchorElem.click();
                        }
                      }}
                      sx={{width: '100%'}}
                      startIcon={<DownloadIcon />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {!!userDetail && <StyledButton
                      label="Notes"
                      id='notes'
                      contained
                      onClick={(v) => {
                        window.open(`/user/${userDetail.id}/notes`, "_BLANK");
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />}
                  </Grid>
                  <Grid item xs={6}>
                    {!!userDetail && <StyledButton
                      label="Audits"
                      id='audits'
                      // contained
                      onClick={(v) => {
                        window.open(`/user/${userDetail.id}/audits`, "_BLANK");
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />}
                  </Grid>
                  <Grid item xs={6}>
                    {!!userDetail && <StyledButton
                      label="Transactions"
                      id='transactions'
                      // contained
                      onClick={(v) => {
                        navigate("/transactions", {state: {user_id: userDetail.id}});
                      }}
                      sx={{width: '100%'}}
                    />}
                  </Grid>
                  <Grid item xs={6}>
                    {!!userDetail && <StyledButton
                      label="Emails"
                      id='emails'
                      // contained
                      onClick={(v) => {
                        window.open(`/user/${userDetail.id}/emails`, "_BLANK");
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />}
                  </Grid>
                  <Grid item xs={6}>
                    {!!userDetail && <StyledButton
                      label="ChangeHist"
                      id='change_hist'
                      // contained
                      grayed_out
                      onClick={(v) => {
                        
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledButton
                      label="Invalid addr. email"
                      id='invalid_address'
                      onClick={(v) => {
                        navigate(`/user/${userDetail?.id}/invalid_address`);
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>
                  {!!userDetail && !!userDetail?.sec_q_status && <Grid item xs={6}>
                    <StyledButton
                      label="Remove SecQ"
                      id='remove_secq'
                      onClick={(v) => {
                        saveUser({
                          sq1_id: '',
                          sq2_id: '',
                          sq3_id: '',
                          sq1_answer: '',
                          sq2_answer: '',
                          sq3_answer: ''
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}
                  {!!userDetail && !!userDetail?.mfa_status && <Grid item xs={6}>
                    <StyledButton
                      label="Remove MFA"
                      id='remove_mfa'
                      onClick={(v) => {
                        saveUser({
                          mfa_status: '0'
                        });
                      }}
                      sx={{width: '100%'}}
                    />
                  </Grid>}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledButton
                      label="Blank transf."
                      id='pdf_transfer'
                      onClick={(v) => {
                        generateBlankTransfer();
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Fisc. Attest (brief)"
                      id='pdf_brief_fiscal'
                      onClick={(v) => {
                        generateFiscal(1);
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Register"
                      id='pdf_register'
                      onClick={(v) => {
                        generate_register();
                      }}
                      sx={{width: '100%'}}
                      startIcon={<CachedIcon />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Fisc Attest"
                      id='pdf_fisc_attest'
                      onClick={(v) => {
                        generateFiscal(0);
                      }}
                      sx={{width: '100%'}}
                      startIcon={<LaunchIcon />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      label="Gen. ImpCode"
                      id='gen_impcode'
                      onClick={(v) => {
                        generateImpersonate();
                      }}
                      sx={{width: '100%'}}
                      startIcon={<DesignServicesIcon />}
                    />
                  </Grid>

                </>}
              </Grid>
            </Box>
          </Grid>
          {!editing && !exiting && !deathing && !officeQuits && <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              // borderColor: theme.palette.primary.main,
              borderRadius: 1,
              padding: 1
            }}>
              <Grid container spacing="8">
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{textAlign: 'center', borderBottom: '1px solid transparent', borderColor: theme.palette.primary.main}}>Documents</Typography>
                </Grid>

                {!!userDetail?.document_list && Object.keys(userDetail.document_list).sort().reverse().map(year => (!!userDetail?.document_list && userDetail.document_list[year].length > 0 ? <>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{fontStyle: 'italic'}}>{year}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List sx={{padding: 0}}>
                      {!!userDetail.document_list && !!userDetail.document_list[year] && userDetail.document_list[year].map(document => <>
                        <ListItem sx={{cursor: 'pointer', backgroundColor: theme.palette.quaternary?.main, marginBottom: 0.5}} onClick={(e) => {
                          e.preventDefault();
                          window.open(document[2], "_BLANK");
                        }}>
                          <ListItemAvatar>
                            <Avatar sx={{backgroundColor: theme.palette.primary.main}}><DownloadIcon /></Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={document[0]} secondary={DateTime.fromISO(document[1]).toFormat("DDD TT")} />
                        </ListItem>
                      </>)}
                    </List>
                  </Grid>

                </> : <></>))}
              </Grid>
            </Box>
          </Grid>}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!userSavedSnackOpen && <AlertSaved open={userSavedSnackOpen} setOpen={(b) => {setUserSavedSnackOpen(b);}} />}
      <a id="downloadAnchorElem" style={{display: "none"}}></a>
    </Page>
  );
}
