import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import {
  LanguageFab,
  Page,
  StyledButton,
  StyledDateField,
  StyledDropzone,
  StyledEditor,
  StyledEditorNoTransl,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../components/interface';

// CONFIG 1/6
import {
  Banner,
  BenefitTag,
  Emission,
  ExitReason,
  Faq,
  Frontpager,
  Office,
  OfficeStatic,
  Raffle,
  SecurityQuestion,
  Share,
  Static
} from '../../models';

// CONFIG 2/6
type ContentEditPageProps = {
  object_type: 'faq' | 'security_question' | 'share' | 'static' | 'banner' | 'tag' | 'office' | 'office_static' | 'emission' | 'frontpager' | 'raffle' | 'exit_reason';
  return_path: string;
};

export const ContentEditPage: FunctionComponent<ContentEditPageProps> = ({object_type, return_path}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const params = useParams();
  const objectId: string | undefined = params.id;

  const [login, setLogin] = useAtom(loginAtom);
  const [editableObject, setEditableObject] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(true);
  const [activeLocale, setActiveLocale] = useState<'nl'|'fr'>('nl');
  const [item, setItem] = useState<File>();
  const [items, setItems] = useState<{[z:string]: File}>({});
  const [formDetails, setFormDetails] = useState<any[]>([]);
  const [tags, setTags] = useState<BenefitTag[]>([]);

  let setting_languages_split_raw = '0';
  if (!!login && !!login.extra_data?.settings?.languages_split) setting_languages_split_raw = login.extra_data.settings.languages_split;
  let setting_languages_split = setting_languages_split_raw === "1";

// CONFIG 3/6
  const fields = {
    'banner': ['id', 'body', 'cta_text', 'cta_link', 'is_stock', 'is_who', 'get_item', 'item_blob|content_type', 'item|filename/byte_size'],
    'emission': ['id', 'budget', 'start_date', 'end_date', 'payment_import_stop', 'hidden', 'enabled', 'approval_date'],
    'exit_reason': ['id', 'name', 'is_hidden', 'weight'],
    'faq': ['id', 'title', 'body', 'enabled', 'category_id'],
    'frontpager': ['id', 'title', 'body', 'cta_link', 'cta_text', 'enabled', 'enabled_offices', 'v2_enabled', 'v2_fade_opacity', 'get_item', 'get_item_xs', 'get_item_sm', 'get_item_md', 'get_item_lg', 'get_item_xl', 'item_blob|content_type', 'item|filename/byte_size', 'item_xs_blob|content_type', 'item_xs|filename/byte_size', 'item_sm_blob|content_type', 'item_sm|filename/byte_size', 'item_md_blob|content_type', 'item_md|filename/byte_size', 'item_lg_blob|content_type', 'item_lg|filename/byte_size', 'item_xl_blob|content_type', 'item_xl|filename/byte_size'],
    'office': ['id', 'office_number', 'ond_number', 'fsma_number', 'enabled', 'mandate_name', 'office_holder', 'email', 'phone', 'street', 'number', 'zip', 'city', 'not_imported', 'locale'],
    'office_static': ['id', 'identifier', 'body'],
    'raffle': ['id', 'visible_start', 'visible_end', 'raffle_start', 'raffle_end', 'name', 'description', 'is_schifting', 'schiftingsvraag', 'get_item', 'item_blob|content_type', 'item|filename/byte_size', 'is_multi_option_1', 'multi_option_1', 'multi_option_1_question'],
    'security_question': ['id', 'question_nl', 'question_fr'],
    'share': ['id', 'tag', 'public_name', 'amount', 'max_per_user', 'votes_per_share', 'is_hidden'],
    'static': ['id', 'identifier', 'alternate_name', 'body', 'enabled', 'is_used_offices', 'is_used_react'],
    'tag': ['id', 'name']
  };


  const fetchTags = () => {
    if (object_type === 'faq') {
      fetch_all<BenefitTag>('/nl/v3/objects/fetch_all', {
        object: 'faq_tag',
        fields: ['id', 'name'],
        per_page: 1000,
        page: 0,
        order: "name ASC",
        filter: {}
      }, (obs, complete_data) => {
        setTags(obs);
      }, (z) => {
        
      }, login);
    }
  };
  const fetchEditableObject = () => {
    setLoader(true);
    
    if (!!objectId) {
      if (!!login) {
        let pars = {
          object: object_type,
          fields: fields[object_type],
          id: objectId
        };

// CONFIG 4/6
        if (object_type === 'banner') fetch_one<Banner>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'emission') fetch_one<Emission>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'exit_reason') fetch_one<ExitReason>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'faq') fetch_one<Faq>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'frontpager') fetch_one<Frontpager>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'office') fetch_one<Office>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'office_static') fetch_one<OfficeStatic>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'raffle') fetch_one<Raffle>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'security_question') fetch_one<SecurityQuestion>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'share') fetch_one<Share>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'static') fetch_one<Static>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
        if (object_type === 'tag') fetch_one<BenefitTag>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, login);
      }
    } else {
      setLoader(false);
    }
  };

  const buildFormData = (formData:FormData, data:object|any, parentKey:string|undefined) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      if (Array.isArray(data)) {
        Object.values(data).forEach(value => {
          if (!!value) formData.append(`${parentKey}[]`, value.toString());
        });
      }  else {
        Object.keys(data).forEach(key => {
          if (key.indexOf('get_item') < 0) buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      }
    } else {
      const value = data == null ? '' : data;
  
      if (!!parentKey) formData.append(parentKey, value);
    }
  }

  const saveEditableObject = (navigate_away:boolean) => {
    setLoader(true);
    
    if (!!editableObject) {
      let pars = {
        object: object_type,
        fields: fields[object_type],
        ob: editableObject
      };

      const formData = new FormData();
      buildFormData(formData, pars, undefined);
      if (!!item) formData.append('files[item]', item);
      if (Object.keys(items).length > 0) {
        Object.keys(items).forEach(item_key => {
          formData.append(`files[${item_key}]`, items[item_key]);
        })
      }
      
// CONFIG 5/6
      if (object_type === 'banner') fetch_one<Banner>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'emission') fetch_one<Emission>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'exit_reason') fetch_one<ExitReason>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'faq') fetch_one<Faq>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'frontpager') fetch_one<Frontpager>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'office') fetch_one<Office>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'office_static') fetch_one<OfficeStatic>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'raffle') fetch_one<Raffle>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'security_question') fetch_one<SecurityQuestion>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'share') fetch_one<Share>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'static') fetch_one<Static>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
      if (object_type === 'tag') fetch_one<BenefitTag>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {}, login);
    }
  };

  useEffect(() => {
    fetchEditableObject();
    fetchTags();

// CONFIG 6/6
    let fd: any[] = [];
    // [gridsize (unused when translatable), type, translatable, label, field, title]
    if (object_type === 'banner') {
      fd.push([12, 'editor', true, 'Body', 'body']);
      fd.push([4, 'switch', false, 'Is Stock', 'is_stock']);
      fd.push([4, 'switch', false, 'Is Who', 'is_who']);
      fd.push([6, 'string', true, 'CTA text', 'cta_text']);
      fd.push([6, 'string', true, 'CTA link', 'cta_link']);
      fd.push([12, 'upload', false, 'Item', 'item']);
    }

    if (object_type === 'emission') {
      fd.push([6, 'date', false, 'Start date', 'start_date']);
      fd.push([6, 'date', false, 'End date', 'end_date']);
      fd.push([12, 'string', false, 'Budget', 'budget']);
      fd.push([12, 'string', false, 'Payment Importer Limit', 'payment_import_stop']);
      fd.push([12, 'divider']);
      fd.push([6, 'date', false, 'Approval date', 'approval_date']);
      fd.push([12, 'divider']);
      fd.push([4, 'switch', false, 'Is active', 'enabled']);
      fd.push([4, 'switch', false, 'Hidden for offices', 'hidden']);
    }

    if (object_type === 'exit_reason') {
      fd.push([12, 'string', true, 'Name', 'name']);
      fd.push([6, 'switch', false, 'Is hidden', 'is_hidden']);
      fd.push([6, 'string', false, 'Weight', 'weight']);
    }

    if (object_type === 'faq') {
      fd.push([12, 'string', true, 'Title', 'title']);
      fd.push([12, 'editor', true, 'Body', 'body']);
      fd.push([4, 'tags', false, 'Category', 'category_id']);
      fd.push([4, 'switch', false, 'Is active', 'enabled']);
    }

    if (object_type === 'frontpager') {
      fd.push([12, 'string', true, 'Title', 'title', undefined, true]);
      fd.push([12, 'string', true, 'Body', 'body', undefined, true]);
      fd.push([6, 'string', true, 'CTA Text', 'cta_text']);
      fd.push([6, 'string', true, 'CTA Link', 'cta_link']);
      // fd.push([4, 'switch', false, 'Is active', 'enabled']);
      fd.push([4, 'switch', false, 'Is active V2', 'v2_enabled']);
      fd.push([4, 'switch', false, 'Is active (offices)', 'enabled_offices']);
      fd.push([12, 'divider']);
      fd.push([12, 'upload', false, 'Item (all sizes)', 'item']);
      fd.push([12, 'string', false, 'Fade (0-100)', 'v2_fade_opacity']);
      fd.push([12, 'divider']);
      fd.push([12, 'upload', false, 'Item XS', 'item_xs', '(0px-599px)']);
      fd.push([12, 'upload', false, 'Item SM', 'item_sm', '(600px-899px)']);
      fd.push([12, 'upload', false, 'Item MD', 'item_md', '(900px-1199px)']);
      fd.push([12, 'upload', false, 'Item LG', 'item_lg', '(1200px-1535px)']);
      fd.push([12, 'upload', false, 'Item XL', 'item_xl', '(1536px-...)']);
    }

    if (object_type === 'office') {
      fd.push([6, 'string', false, 'Office Number', 'office_number']);
      fd.push([6, 'string', false, 'Ond. Number', 'ond_number']);
      fd.push([6, 'string', false, 'FSMA Number', 'fsma_number']);
      fd.push([12, 'string', false, 'Office name', 'mandate_name']);
      fd.push([12, 'string', false, 'Office holder', 'office_holder']);
      fd.push([6, 'string', false, 'Email', 'email']);
      fd.push([6, 'string', false, 'Phone', 'phone']);
      fd.push([12, 'divider']);
      fd.push([8, 'string', false, 'Street', 'street']);
      fd.push([4, 'string', false, 'Number', 'number']);
      fd.push([4, 'string', false, 'Zip', 'zip']);
      fd.push([8, 'string', false, 'City', 'city']);
      fd.push([12, 'divider']);
      fd.push([4, 'switch', false, 'Not imported', 'not_imported']);
      fd.push([4, 'switch', false, 'Is active', 'enabled']);
      fd.push([6, 'locale_select', false, '', 'locale']);
    }

    if (object_type === 'office_static') {
      fd.push([12, 'string', false, 'Identifier', 'identifier']);
      fd.push([12, 'editor', true, 'Body', 'body']);
    }

    if (object_type === 'raffle') {
      fd.push([6, 'date', false, 'Visible from', 'visible_start']);
      fd.push([6, 'date', false, 'Visible to', 'visible_end']);
      fd.push([6, 'date', false, 'Raffle active from', 'raffle_start']);
      fd.push([6, 'date', false, 'Raffle active to', 'raffle_end']);
      fd.push([12, 'divider']);
      fd.push([12, 'upload', false, 'Item', 'item']);
      fd.push([12, 'string', true, 'Name', 'name']);
      fd.push([12, 'editor', true, 'Body', 'description']);
      fd.push([12, 'divider']);
      fd.push([4, 'switch', false, 'Has schiftingsvraag', 'is_schifting']);
      fd.push([12, 'editor', true, 'Schiftingsvraag', 'schiftingsvraag']);
      fd.push([12, 'divider']);
      fd.push([4, 'switch', false, 'Has multi-option', 'is_multi_option_1']);
      fd.push([12, 'editor', true, 'Multi-option Question', 'multi_option_1_question']);
      fd.push([12, 'string', true, 'Multi-option AnswerOptions (1 per line)', 'multi_option_1', undefined, true]);
    }

    if (object_type === 'security_question') {
      fd.push([12, 'editor', false, 'Question (nl)', 'question_nl']);
      fd.push([12, 'editor', false, 'Question (fr)', 'question_fr']);
    }

    if (object_type === 'share') {
      fd.push([6, 'string', false, 'Tag', 'tag']);
      fd.push([6, 'string', false, 'Public name', 'public_name']);
      fd.push([12, 'string', false, 'Amount', 'amount']);
      fd.push([6, 'string', false, 'Max/User', 'max_per_user']);
      fd.push([6, 'string', false, 'Votes/Share', 'votes_per_share']);
      fd.push([4, 'switch', false, 'Is hidden', 'is_hidden']);
    }

    if (object_type === 'static') {
      fd.push([12, 'string', false, 'Identifier', 'identifier']);
      fd.push([12, 'string', false, 'Alternate names', 'alternate_name', <>Separate with a pipe ( | ) to use multiple alternate names. <StyledButton label="Add a pipe" id='add_pipe' contained onClick={(v) => {
        if (!!editableObject) setEditableObject({
          ...editableObject,
          alternate_name: `${(editableObject.alternate_name || '')} | `
        });
      }} sx={{marginLeft: 1, paddingTop: 0, paddingBottom: 0, scale: '0.8'}} /></>]);
      fd.push([12, 'editor', true, 'Body', 'body']);
      fd.push([4, 'switch', false, 'Used in backoffice', 'is_used_offices']);
      fd.push([4, 'switch', false, 'Used on new website', 'is_used_react']);
      fd.push([4, 'switch', false, 'Is active', 'enabled']);
    }

    if (object_type === 'tag') {
      fd.push([12, 'string', true, 'Name', 'name']);
    }
    setFormDetails(fd);
  }, []);

  // let is_multi = false;
  // formDetails.map(formDetail => {
  //   if (!is_multi) is_multi = !!formDetail[2];
  // })
// console.log(editableObject);
// console.log("aaa");
  return (
    <Page sx={{
      ...(object_type === 'banner' ? {
        ".sun-editor-editable": {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      } : {})
    }} title={`Edit ${object_type}`}>
      {formDetails.filter(fd => fd[2]).length > 0 && <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {setActiveLocale(lc);}}
      />}
      <Grid container spacing="8" sx={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto'}}>

        {formDetails.length > 0 && formDetails.map(formData => <>

          {formData[1] === 'string' && <>
            {!!formData[2] && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <StyledTextFieldTransl
                label={formData[3].toString()}
                activeLocale={activeLocale}
                value={editableObject[formData[4].toString()] || {nl: '', fr: ''}}
                id={formData[4].toString()}
                onChange={(v) => {if (!!editableObject) setEditableObject({
                  ...editableObject,
                  [formData[4].toString()]: v});
                }}
                fullWidth
                helperText={formData[5]}
                multiline={!!formData[6]}
              />
            </Grid>}
            {!formData[2] && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <StyledTextField
                label={formData[3].toString()}
                value={editableObject[formData[4].toString()] || ''}
                id={formData[4].toString()}
                onChange={(v) => {if (!!editableObject) setEditableObject({
                  ...editableObject,
                  [formData[4].toString()]: v});
                }}
                fullWidth
                helperText={formData[5]}
                multiline={!!formData[6]}
              />
            </Grid>}
          </>}

          {formData[1] === 'editor' && (!!formData[2] ? <StyledEditor
            label={formData[3].toString()}
            value={{nl: editableObject[formData[4].toString()]?.nl || '', fr: editableObject[formData[4].toString()]?.fr || ''}}
            activeLocale={activeLocale}
            onChange={(ob) => {
              if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: ob
              });
            }}
          /> : <StyledEditorNoTransl
            label={formData[3].toString()}
            value={editableObject[formData[4].toString()] || ''}
            onChange={(ob) => {
              if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: ob
              });
            }}
          />)}

          {formData[1] === 'switch' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <StyledSwitch
              label={formData[3].toString()}
              value={!!editableObject[formData[4].toString()]}
              onChange={(v) => {if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: !!v
              });}}
            />
          </Grid>}

          {formData[1] === 'date' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <StyledDateField
              label={formData[3].toString()}
              value={editableObject[formData[4].toString()] || ''}
              id={formData[4].toString()}
              onChange={(v) => {if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: v});
              }}
              fullWidth
            />
          </Grid>}

          {formData[1] === 'divider' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <Divider sx={{marginTop: 2, marginBottom: 2}} />
          </Grid>}

          {formData[1] === 'locale_select' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <StyledSelect
              label="Language"
              value={editableObject[formData[4].toString()] || 'nl'}
              id={formData[4].toString()}
              onChange={(v) => {if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: v
              });}}
              list={[
                {id: 'nl', name: "Dutch"},
                {id: 'fr', name: "French"}
              ]}
            />
          </Grid>}

          {formData[1] === 'tags' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <StyledSelect
              label="Category"
              value={editableObject[formData[4].toString()] || 'nl'}
              id={formData[4].toString()}
              onChange={(v) => {if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: v
              });}}
              list={(tags || []).map(tag => {return {id: tag.id || '', name: tag.name?.nl || ''};})}
            />
          </Grid>}

          {formData[1] === 'upload' && formData[4] === 'item' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <Typography sx={{fontSize: '0.75rem'}}>Item</Typography>
            <StyledDropzone
              acceptedFiles={{}}
              onDrop={acceptedFiles => {
                if (item !== acceptedFiles[0]) setItem(acceptedFiles[0]);
              }}
              multiple={false}
              maxFiles={1}
              maxSize={3 * 1000 * 1000}
              items={!!item ? [item] : []}
              dropText="Click here, or drop some files."
              dropHint="Allowed: 1 item < 3mb"
            />
            {!!editableObject?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {editableObject?.item_filename} ({(parseInt(editableObject?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
          </Grid>}

          {formData[1] === 'upload' && formData[4] !== 'item' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <Typography sx={{fontSize: '0.75rem'}}>{formData[3].toString()}</Typography>
            <StyledDropzone
              acceptedFiles={{}}
              onDrop={acceptedFiles => {
                if (!!items && items[formData[4]] !== acceptedFiles[0]) setItems({
                  ...items,
                  [formData[4]]: acceptedFiles[0]
                });
              }}
              multiple={false}
              maxFiles={1}
              maxSize={3 * 1000 * 1000}
              items={!!items && items[formData[4]] ? [items[formData[4]]] : []}
              dropText="Click here, or drop some files."
              dropHint="Allowed: 1 item < 3mb"
            />
            {!!editableObject && !!editableObject[`${formData[4]}_filename`] && <Typography sx={{fontSize: '0.75rem'}}>Current: {editableObject[`${formData[4]}_filename`]} ({(parseInt(editableObject[`${formData[4]}_byte_size`] || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
            {!!formData[5] && <Typography sx={{fontSize: '0.75rem', fontStyle: 'italic', color: 'lightgrey', marginBottom: 2}}>{formData[5]}</Typography>}
          </Grid>}

        </>)}
        
        <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Save and re-open"
            id='save_reopen'
            contained
            saveStartIcon
            onClick={(v) => {
              saveEditableObject(false);
            }}
          />}
          {!loader && <StyledButton
            label="Save"
            id='save'
            contained
            saveStartIcon
            onClick={(v) => {
              saveEditableObject(true);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label="Cancel"
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(return_path);
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>
    </Page>
  );
}
