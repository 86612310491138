import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../lib/server_helper';
import { loginAtom } from '../lib/auth';

import {
  Box,
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { BookYear } from '../models/BookYear';
import { Dividend } from '../models/Dividend';
import { Emission } from '../models/Emission';
import { Export } from '../models/Export';
import { Meeting } from '../models/Meeting';

import {
  DataGrid,
  Page,
  StyledButton,
  StyledDateField,
  StyledIconButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../components/interface';

import { AlertSomethingWentWrong } from '../components/alerts/SomethingWentWrong';

const { DateTime } = require("luxon");

type ExportsProps = {};

export const Exports: FunctionComponent<ExportsProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  const [login, setLogin] = useAtom(loginAtom);
  const [newExport, setNewExport] = useState<Export>({user_id: login?.id, settings: {table: 'users', enabled: [0, 1], statuses: [0], checked: 1}});
  const [exports, setExports] = useState<Export[]>([]);
  const [emissions, setEmissions] = useState<Emission[]>([]);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [dividends, setDividends] = useState<Dividend[]>([]);
  const [bookYears, setBookYears] = useState<BookYear[]>([]);
  const [exportsCount, setExportsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("updated_at DESC");
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  useEffect(() => {
    onSearch();
  }, [page, order]);

  const doSave = () => {
    fetch_one<Export>('/nl/v3/objects/save', {
      'object': 'export',
      ob: newExport
    }, (obs, complete_data) => {
      setShowEdit(false);
      setNewExport({user_id: login?.id, settings: {enabled: [0, 1], statuses: [0]}});
      onSearch();
    }, (z) => {

    }, login);
  };

  const getBookYears = () => {
    fetch_all<BookYear>('/nl/v3/objects/fetch_all', {
      object: 'book_year',
      fields: ['id', 'short_visual'],
      per_page: 1000,
      page: 0,
      order: "id DESC"
    }, (obs, complete_data) => {
      setBookYears(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const getDividends = () => {
    fetch_all<Dividend>('/nl/v3/objects/fetch_all', {
      object: 'dividend',
      fields: ['id', 'book_year|short_visual'],
      per_page: 1000,
      page: 0,
      order: "id DESC"
    }, (obs, complete_data) => {
      setDividends(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const getEmissions = () => {
    fetch_all<Emission>('/nl/v3/objects/fetch_all', {
      object: 'emission',
      fields: ['id', 'start_date'],
      per_page: 1000,
      page: 0,
      order: "start_date DESC"
    }, (obs, complete_data) => {
      setEmissions(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const getMeetings = () => {
    fetch_all<Meeting>('/nl/v3/objects/fetch_all', {
      object: 'meeting',
      fields: ['id', 'name'],
      per_page: 1000,
      page: 0,
      order: "id DESC"
    }, (obs, complete_data) => {
      setMeetings(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<Export>('/nl/v3/objects/remove', {
      object: 'export',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch();
      setRemoveLoader(false);
    }, (z) => {

    }, login);
  };

  const runIt = (id: number) => {
    setLoader(true);
    fetch_one<Export>('/nl/v3/objects/custom_action', {
      object: 'export',
      class_action: 'custom_api_run_it',
      handler_id: login?.id,
      id: id
    }, (obs, complete_data) => {
      onSearch();
    }, (z) => {
      onSearch();
    }, login);
  };

  const onSearch = () => {
    setLoader(true);
    fetch_all<Export>('/nl/v3/objects/fetch_all', {
      object: 'export',
      fields: ['id', 'created_at', 'finished_at', 'get_item', 'settings', 'user|first_name'],
      per_page: 20,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setExports(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setExportsCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  useEffect(() => {
    getBookYears();
    getDividends();
    getEmissions();
    getMeetings();
  }, []);

  // useEffect(() => {
  //   console.log(newExport);
  // }, [newExport]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150, sortable: false },
    { field: 'user_first_name', headerName: 'User', width: 150, sortable: false },
    { field: 'table_name', headerName: 'Name', width: 350, sortable: false },
    { field: 'created_at', headerName: 'Created', width: 200, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D TT") },
    { field: 'finished_at', headerName: 'Finished', width: 200, renderCell: params => {
      let diff = DateTime.fromISO(params.row.finished_at).diff(DateTime.fromISO(params.row.created_at), ['hours', 'minutes', 'seconds']).toObject();
      return (<>
        <Box>
          {!!params.row.finished_at && DateTime.fromISO(params?.value).toFormat("D TT")}{!params.row.finished_at && "-"}<br/>
          {!!params.row.finished_at && <span style={{fontSize: '0.6rem'}}>
            {diff.hours > 0 && <>{diff.hours} hours, </>}
            {diff.minutes > 0 && <>{diff.minutes} minutes, </>}
            {diff.seconds > 0 && <>{diff.seconds} seconds</>}
          </span>}
        </Box>
      </>);
    } }
  ];

  return (
    <Page sx={{}} title="Exports" helper="exports" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        onClick={() => {
          setShowEdit(true);
        }}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {!!showEdit && <>
        <Grid container sx={{position: 'relative'}}>
          <Grid item xs={12}>
            <CloseIcon sx={{position: 'absolute', top: -12, right: 0, cursor: 'pointer'}} onClick={() => {setShowEdit(false);}} />
            
            <StyledSelect
              label="Export Type"
              value={newExport?.settings?.table || 'users'}
              id='export_type'
              onChange={(v) => {
                setNewExport({
                  ...newExport,
                  settings: {
                    ...(newExport.settings || {}),
                    table: v
                  }
                });
              }}
              list={[
                // {id: 'contacts', name: "Contact requests"},
                {id: 'dividend_calculations', name: "Dividend Calculations"},
                {id: 'keys', name: "Emission Keys"},
                {id: 'payments', name: "Emission Payments"},
                {id: 'repay', name: "Emission Repay-list"},
                // {id: 'log_sources', name: "Log Sources"},
                // {id: 'logs', name: "Logs"},
                {id: 'meeting_attendees', name: "Meeting Attendees"},
                {id: 'office_accounts', name: "Office Accounts"},
                {id: 'statics', name: "Statics"},
                {id: 'transactions', name: "Transactions"},
                // {id: 'uitschrijving_stats', name: "Uitschrijving Stats"},
                {id: 'uitschrijvingen', name: "Uitschrijvingen"},
                {id: 'users', name: "Users"},
                {id: 'volmachten', name: "Volmachten"},
                {id: 'waiting', name: "Waiting list"},
                // {id: 'web_logs', name: "Web logs"}
              ]}
            />
          </Grid>

          {/* Created at */}
          {['contacts', 'logs', 'transactions', 'users', 'uitschrijvingen', 'web_logs'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Created at between</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="From"
                value={newExport?.settings?.from || ''}
                id='cr_from'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      from: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'center'}}><Typography sx={{marginTop: 1.5}}>and</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="To"
                value={newExport?.settings?.to || ''}
                id='cr_to'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      to: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>}

          {/* Last imported */}
          {['users'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Last imported between</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="From"
                value={newExport?.settings?.li_from || ''}
                id='li_from'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      li_from: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'center'}}><Typography sx={{marginTop: 1.5}}>and</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="To"
                value={newExport?.settings?.li_to || ''}
                id='li_to'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      li_to: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>}

          {/* Transaction date */}
          {['transactions'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Transaction date between</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="From"
                value={newExport?.settings?.trans_from || ''}
                id='trans_from'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      trans_from: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'center'}}><Typography sx={{marginTop: 1.5}}>and</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="To"
                value={newExport?.settings?.trans_to || ''}
                id='trans_to'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      trans_to: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>}

          {/* Last updated */}
          {['users'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Last updated between</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="From"
                value={newExport?.settings?.lu_from || ''}
                id='lu_from'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      lu_from: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'center'}}><Typography sx={{marginTop: 1.5}}>and</Typography></Grid>
            <Grid item xs={4}>
              <StyledDateField
                label="To"
                value={newExport?.settings?.lu_to || ''}
                id='lu_to'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      lu_to: v.toString()
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>}

          {/* Enabled */}
          {['users'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 0.5}}>Enabled</Typography></Grid>
            <Grid item xs={10}>
              <StyledButton
                label="Enabled"
                contained={(newExport?.settings?.enabled || []).indexOf(1) > -1}
                secondary={(newExport?.settings?.enabled || []).indexOf(1) < 0}
                id='enabled1'
                onClick={(vv) => {
                  let newEnabled: number[] = [];

                  if ((newExport?.settings?.enabled || []).indexOf(1) > -1) {
                    newEnabled = (newExport?.settings?.enabled || []).filter(x => x !== 1);
                  } else {
                    newEnabled = [...(newExport?.settings?.enabled || []), 1];
                  }

                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      enabled: newEnabled
                    }
                  });
                }}
              />
              <StyledButton
                label="Disabled"
                contained={(newExport?.settings?.enabled || []).indexOf(0) > -1}
                secondary={(newExport?.settings?.enabled || []).indexOf(0) < 0}
                id='enabled0'
                onClick={(vv) => {
                  let newEnabled: number[] = [];

                  if ((newExport?.settings?.enabled || []).indexOf(0) > -1) {
                    newEnabled = (newExport?.settings?.enabled || []).filter(x => x !== 0);
                  } else {
                    newEnabled = [...(newExport?.settings?.enabled || []), 0];
                  }

                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      enabled: newEnabled
                    }
                  });
                }}
                sx={{marginLeft: 1}}
              />
            </Grid>
          </Grid>}

          {/* UserType */}
          {['users', 'transactions'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>VennNr starts with</Typography></Grid>
            <Grid item xs={10}>
              <StyledTextField
                label="VennNr"
                value={newExport?.settings?.user_type || ''}
                id="user_type"
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      user_type: v
                    }
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>}

          {/* Contact Status */}
          {['contacts'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 0.5}}>Have status</Typography></Grid>
            <Grid item xs={10}>
              <StyledButton
                label="No status"
                contained={(newExport?.settings?.statuses || []).indexOf(0) > -1}
                secondary={(newExport?.settings?.statuses || []).indexOf(0) < 0}
                id='statuses0'
                onClick={(vv) => {
                  let newStatuses: number[] = [];

                  if ((newExport?.settings?.statuses || []).indexOf(0) > -1) {
                    newStatuses = (newExport?.settings?.statuses || []).filter(x => x !== 0);
                  } else {
                    newStatuses = [...(newExport?.settings?.statuses || []), 0];
                  }

                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      statuses: newStatuses
                    }
                  });
                }}
              />
              OTHER STATUSES (Tag::ContactStatus)
            </Grid>
          </Grid>}

          {/* Locales */}
          {['statics'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 0.5}}>Locales</Typography></Grid>
            <Grid item xs={10}>
              <StyledButton
                label="NL"
                contained={(newExport?.settings?.locales || []).indexOf('nl') > -1}
                secondary={(newExport?.settings?.locales || []).indexOf('nl') < 0}
                id='locales0'
                onClick={(vv) => {
                  let newLocales: string[] = [];

                  if ((newExport?.settings?.locales || []).indexOf('nl') > -1) {
                    newLocales = (newExport?.settings?.locales || []).filter(x => x !== 'nl');
                  } else {
                    newLocales = [...(newExport?.settings?.locales || []), 'nl'];
                  }

                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      locales: newLocales
                    }
                  });
                }}
              />
              <StyledButton
                label="FR"
                sx={{marginLeft: 1}}
                contained={(newExport?.settings?.locales || []).indexOf('fr') > -1}
                secondary={(newExport?.settings?.locales || []).indexOf('fr') < 0}
                id='locales1'
                onClick={(vv) => {
                  let newLocales: string[] = [];

                  if ((newExport?.settings?.locales || []).indexOf('fr') > -1) {
                    newLocales = (newExport?.settings?.locales || []).filter(x => x !== 'fr');
                  } else {
                    newLocales = [...(newExport?.settings?.locales || []), 'fr'];
                  }

                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      locales: newLocales
                    }
                  });
                }}
              />
            </Grid>
          </Grid>}

          {/* Emission */}
          {['keys', 'payments', 'repay', 'waiting'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Emission</Typography></Grid>
            <Grid item xs={10}>
              <StyledSelect
                label="Emission"
                value={newExport?.settings?.emission?.toString() || ''}
                id='emission_id'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      emission: !!v ? parseInt(v, 10) : undefined
                    }
                  });
                }}
                list={[
                  {id: '', name: "--- None ---"},
                  ...emissions.map(m => {return {id: m.id?.toString() || '', name: `Emission of ${DateTime.fromISO(m.start_date).toFormat("D")}`};})
                ]}
              />
            </Grid>
          </Grid>}

          {/* Meeting */}
          {['meeting_attendees', 'volmachten'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Meeting</Typography></Grid>
            <Grid item xs={10}>
              <StyledSelect
                label="Meeting"
                value={newExport?.settings?.meeting?.toString() || ''}
                id='meeting_id'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      meeting: !!v ? parseInt(v, 10) : undefined
                    }
                  });
                }}
                list={[
                  {id: '', name: "--- None ---"},
                  ...meetings.map(m => {return {id: m.id?.toString() || '', name: m.name};})
                ]}
              />
            </Grid>
          </Grid>}

          {/* Dividend */}
          {['dividend_calculations'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Div. Calculation Setting</Typography></Grid>
            <Grid item xs={10}>
              <StyledSelect
                label="Dividend"
                value={newExport?.settings?.dividend?.toString() || ''}
                id='meeting_id'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      dividend: !!v ? parseInt(v, 10) : undefined
                    }
                  });
                }}
                list={[
                  {id: '', name: "--- None ---"},
                  ...dividends.map(m => {return {id: m.id?.toString() || '', name: m.book_year_short_visual};})
                ]}
              />
            </Grid>
          </Grid>}

          {/* BookYear */}
          {['uitschrijvingen', 'uitschrijving_stats'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Book Year</Typography></Grid>
            <Grid item xs={10}>
              <StyledSelect
                label="BookYear"
                value={newExport?.settings?.year || ''}
                id='meeting_id'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      year: v
                    }
                  });
                }}
                list={[
                  {id: '', name: "--- None ---"},
                  {id: 'BLANK', name: "No BookYear (Unwilling Uitschrijving)"},
                  ...bookYears.map(m => {return {id: m.id?.toString() || '', name: m.short_visual};})
                ]}
              />
            </Grid>
          </Grid>}

          {/* Checked */}
          {['uitschrijvingen'].indexOf(newExport?.settings?.table || 'users') > -1 && <Grid item container xs={12} spacing="0">
            <Grid item xs={2}><Typography sx={{marginTop: 1.5}}>Checked</Typography></Grid>
            <Grid item xs={10}>
              <StyledSelect
                label="Checked"
                value={newExport?.settings?.checked?.toString() || '1'}
                id='checked'
                onChange={(v) => {
                  setNewExport({
                    ...newExport,
                    settings: {
                      ...(newExport.settings || {}),
                      checked: !!v ? parseInt(v, 10) : 1
                    }
                  });
                }}
                list={[
                  {id: '0', name: "Unchecked"},
                  {id: '1', name: "Checked"}
                ]}
              />
            </Grid>
          </Grid>}


          <Grid item xs={12}>
            <StyledButton
              label="Save"
              id='save'
              contained
              onClick={() => {
                doSave();
              }}
              sx={{marginRight: 3}}
              startIcon={<AddCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </>}
    
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            {!params.row.get_item && <StyledIconButton color="primary" onClick={() => {
              runIt(params.row.id);
            }}>
              <DirectionsRunIcon />
            </StyledIconButton>}
            {!!params.row.get_item && (params.row.table_name || '').indexOf("_pain") < 0 && <StyledIconButton color="primary" onClick={() => {
              window.open(params.row.get_item, "_BLANK");
            }}>
              <FileDownloadIcon />
            </StyledIconButton>}
            {!removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={exportsCount}
        objects={(exports || []).map(u => ({
          ...u,
          table_name: u.settings?.table
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
