import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import {
  Avatar,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { Typography } from '../../components/interface/Typography';

import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";

type StyledEditorProps = {
  label: string;
  value: {nl:string, fr:string};
  activeLocale: 'nl' | 'fr';
  onChange: (z:{nl:string, fr:string}) => void;
  simple?: boolean;
};

export const StyledEditor: FunctionComponent<StyledEditorProps> = ({label, onChange, value, activeLocale, simple}) => {
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);
  const editor = useRef<SunEditorCore>();
  const editorNl = useRef<SunEditorCore>();
  const editorFr = useRef<SunEditorCore>();

  const [editorContent, setEditorContent] = useState<string>(value[activeLocale] || value.nl || '');
  const [editorContentNl, setEditorContentNl] = useState<string>(value.nl);
  const [editorContentFr, setEditorContentFr] = useState<string>(value.fr);
  const [inited, setInited] = useState<boolean>(false);

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const getSunEditorNlInstance = (sunEditor: SunEditorCore) => {
    editorNl.current = sunEditor;
  };

  const getSunEditorFrInstance = (sunEditor: SunEditorCore) => {
    editorFr.current = sunEditor;
  };

  let setting_editor_height = '300px';
  if (!!login && !!login.extra_data?.settings?.editor_height) setting_editor_height = login.extra_data.settings.editor_height;
  let setting_languages_split_raw = '0';
  if (!!login && !!login.extra_data?.settings?.languages_split) setting_languages_split_raw = login.extra_data.settings.languages_split;
  let setting_languages_split = (setting_languages_split_raw === "1");
  if (!!simple) setting_editor_height = '100px';

  useEffect(() => {
    if (!inited && !setting_languages_split && value[activeLocale] !== editorContent) {
      // setEditorContent(value[activeLocale]);
      editor.current?.setContents(value[activeLocale]);
      setInited(true);
    }
    if (!inited && !!setting_languages_split && (value.nl !== editorContentNl || value.fr !== editorContentFr)) {
      editorNl.current?.setContents(value.nl);
      editorFr.current?.setContents(value.fr);
      // setEditorContentNl(value.nl);
      // setEditorContentFr(value.fr);
      setInited(true);
    }
  }, [value]);

  useEffect(() => {
    // console.log(activeLocale, setting_languages_split, value, (!!value && !!value[activeLocale] ? value[activeLocale] : '<p><br/></p>'));
    if (!setting_languages_split) {
      editor.current?.setContents((!!value && !!value[activeLocale] ? value[activeLocale] : '<p><br/></p>'));
      // setEditorContent((!!value && !!value[activeLocale] ? value[activeLocale] : '<p><br/></p>'));
    }
  }, [activeLocale]);

  useEffect(() => {
    // console.log('editorcontent changed', value);
    if (!!setting_languages_split) {
      if (!!onChange) onChange({
        nl: editorContentNl || '',
        fr: editorContentFr || ''
      });
    } else {
      if (!!onChange) onChange({
        ...value, 
        [activeLocale]: editorContent || ''
      });
    }
  }, [editorContent, editorContentNl, editorContentFr]);

  return (<>
    {!!setting_languages_split && <>
      <Grid item xs={12}>
        <Typography sx={{fontSize: '0.75rem'}}>{label} (NL)</Typography>
        <SunEditor
          height={setting_editor_height}
          getSunEditorInstance={getSunEditorNlInstance}
          setOptions={{
            defaultStyle: "font-size: 16px; font-family: 'Golos Text', sans-serif",
            buttonList: [
              ...(!!simple ? [
                ['undo', 'redo'],
                ['bold', 'underline', 'italic'],
                ['link'],
                ['fullScreen', 'codeView'],
              ] : [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['paragraphStyle', 'blockquote'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                ['fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                '/',
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['table', 'link', 'image', 'video'],
                // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
                // ['imageGallery'], // You must add the "imageGalleryUrl".
                ['fullScreen', 'showBlocks', 'codeView'],
                ['preview', 'print'],
                // ['save', 'template'],
                // ['dir', 'dir_ltr', 'dir_rtl'],
                // '/', Line break
              ])
            ]
          }}
          setContents={editorContentNl}
          onChange={(v) => {setEditorContentNl(v);}}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{fontSize: '0.75rem'}}>{label} (FR)</Typography>
        <SunEditor
          height={setting_editor_height}
          getSunEditorInstance={getSunEditorFrInstance}
          setOptions={{
            defaultStyle: "font-size: 16px; font-family: 'Golos Text', sans-serif",
            buttonList: [
              ...(!!simple ? [
                ['undo', 'redo'],
                ['bold', 'underline', 'italic'],
                ['link'],
                ['fullScreen', 'codeView'],
              ] : [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['paragraphStyle', 'blockquote'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                ['fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                '/',
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['table', 'link', 'image', 'video'],
                // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
                // ['imageGallery'], // You must add the "imageGalleryUrl".
                ['fullScreen', 'showBlocks', 'codeView'],
                ['preview', 'print'],
                // ['save', 'template'],
                // ['dir', 'dir_ltr', 'dir_rtl'],
                // '/', Line break
              ])
            ]
          }}
          setContents={editorContentFr}
          onChange={(v) => {setEditorContentFr(v);}}
        />
      </Grid>
    </>}
    
    {!setting_languages_split && <Grid item xs={12} sx={{position: 'relative'}}>
      <Avatar
        sx={{position: 'absolute', top: 12, right: 32, zIndex: 999, width: 24, height: 24, fontSize: '0.8rem', backgroundColor: ((value['nl'] || '').replace(/<\/?[^>]+(>|$)/g, "") === '' ? 'red' : theme.palette.tertiary?.main)}}
      >NL</Avatar>
      <Avatar
        sx={{position: 'absolute', top: 12, right: 4, zIndex: 999, width: 24, height: 24, fontSize: '0.8rem', backgroundColor: ((value['fr'] || '').replace(/<\/?[^>]+(>|$)/g, "") === '' ? 'red' : theme.palette.tertiary?.main)}}
      >FR</Avatar>

      <Typography sx={{fontSize: '0.75rem'}}>{label}</Typography>
      <SunEditor
        height={setting_editor_height}
        getSunEditorInstance={getSunEditorInstance}
        setOptions={{
          defaultStyle: "font-size: 16px; font-family: 'Golos Text', sans-serif;",
          buttonList: [
            ...(!!simple ? [
              ['undo', 'redo'],
              ['bold', 'underline', 'italic'],
              ['link'],
              ['fullScreen', 'codeView'],
            ] : [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['paragraphStyle', 'blockquote'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['removeFormat'],
              '/',
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['table', 'link', 'image', 'video'],
              // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
              // ['imageGallery'], // You must add the "imageGalleryUrl".
              ['fullScreen', 'showBlocks', 'codeView'],
              ['preview', 'print'],
              // ['save', 'template'],
              // ['dir', 'dir_ltr', 'dir_rtl'],
              // '/', Line break
            ])
          ]
        }}
        setContents={editorContent}
        onChange={(v) => {setEditorContent(v);}}
      />
    </Grid>}
  </>);
}