import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  BlockPicker,
  ImagePicker,
  LanguageFab,
  StyledButton,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography,
  StyledEditor
} from '../../components/interface';

import { BlockPublicBase } from '../../components/blocks/Public/Base';
import { BlockTranslatableSettingsBase } from '../../components/blocks/TranslatableSettings/Base';

import { NewsBlock } from '../../models/NewsBlock';
import { News } from '../../models/News';
import { NewsTag } from '../../models/NewsTag';

// const { DateTime } = require("luxon");

type ContentNewsDetailProps = {};

export const ContentNewsDetail: FunctionComponent<ContentNewsDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [newsDetail, setNewsDetail] = useState<News|null>(null);
  const [tags, setTags] = useState<NewsTag[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [exitId, setDividendId] = useState<string | undefined>(params.id);

  const [blocks, setBlocks] = useState<NewsBlock[]>([]);

  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();
  const [itemFr, setItemFr] = useState<File>();
  const [itemUrlFr, setItemUrlFr] = useState<string>();

  const [languageEnabled, setLanguageEnabled] = useState([0, 1]);

  const [activeHover, setActiveHover] = useState<number>();
  const [activeLocale, setActiveLocale] = useState<'nl'|'fr'>('nl');

  const [movingMode, setMovingMode] = useState<boolean>(false);

  const [previewLoading, setPreviewLoading] = useState<boolean>(true);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'name', 'extra_data', 'enabled', 'is_hidden', 'get_item_nl', 'get_item_fr',
    'subject', 'block_info', 'weight', 'weight_homepage', 'tag_id', 'tag|id/name', 'teaser', 'nw_intro'
  ];

  const saveSetting = () => {
    setSaveLoader(true);

    let formData = new FormData();
    formData.append('object', 'v2_news');
    formData.append('handler_id', (login?.id || "").toString());
    fields.map(field => {
      formData.append('fields[]', field);
    });
    if (!!exitId) formData.append('ob[id]', exitId.toString());
    if (!!exitId) formData.append('id', exitId.toString());
    // newsDetail
    formData.append('ob[enabled]', !!newsDetail?.enabled ? '1' : '0');
    formData.append('ob[is_hidden]', !!newsDetail?.is_hidden ? '1' : '0');
    formData.append('ob[name]', newsDetail?.name || 'k');
    formData.append('ob[tag_id]', newsDetail?.tag_id?.toString() || 'k');
    formData.append('ob[weight]', newsDetail?.weight?.toString() || 'k');
    formData.append(`ob[subject][nl]`, newsDetail?.subject?.nl || '');
    formData.append(`ob[subject][fr]`, newsDetail?.subject?.fr || '');
    formData.append(`ob[teaser][nl]`, newsDetail?.teaser?.nl || '');
    formData.append(`ob[teaser][fr]`, newsDetail?.teaser?.fr || '');
    formData.append(`ob[nw_intro][nl]`, newsDetail?.nw_intro?.nl || '');
    formData.append(`ob[nw_intro][fr]`, newsDetail?.nw_intro?.fr || '');
    languageEnabled.map(field => {
      formData.append('ob[extra_data][languages][]', field.toString());
    });
    if (!!item) formData.append(`ob[item_nl]`, item);
    if (!!itemUrl) formData.append(`ob[set_item_url_nl]`, itemUrl);
    if (!!itemFr) formData.append(`ob[item_fr]`, itemFr);
    if (!!itemUrlFr) formData.append(`ob[set_item_url_fr]`, itemUrlFr);
    // blocks
    blocks.map((block, i) => {
      formData.append(`blocks[${i}][block_type]`, block.type || '');
      formData.append(`blocks[${i}][id]`, block.id?.toString() || '');
      formData.append(`blocks[${i}][rank]`, i.toString() || '');
      formData.append(`blocks[${i}][extra_data][sep_color]`, block.sep_color || '');
      formData.append(`blocks[${i}][extra_data][string_value][nl]`, block.string_value?.nl || '');
      formData.append(`blocks[${i}][extra_data][string_value][fr]`, block.string_value?.fr || '');
      formData.append(`blocks[${i}][extra_data][title_value][nl]`, block.title_value?.nl || '');
      formData.append(`blocks[${i}][extra_data][title_value][fr]`, block.title_value?.fr || '');
      formData.append(`blocks[${i}][extra_data][string_value_right][nl]`, block.string_value_right?.nl || '');
      formData.append(`blocks[${i}][extra_data][string_value_right][fr]`, block.string_value_right?.fr || '');
      formData.append(`blocks[${i}][extra_data][title_value_right][nl]`, block.title_value_right?.nl || '');
      formData.append(`blocks[${i}][extra_data][title_value_right][fr]`, block.title_value_right?.fr || '');
      formData.append(`blocks[${i}][extra_data][settings][mtop]`, block.settings?.mtop || '');
      formData.append(`blocks[${i}][extra_data][settings][mbot]`, block.settings?.mbot || '');
      formData.append(`blocks[${i}][extra_data][settings][image_url][nl]`, block.settings?.image_url?.nl || '');
      formData.append(`blocks[${i}][extra_data][settings][image_url][fr]`, block.settings?.image_url?.fr || '');
      formData.append(`blocks[${i}][extra_data][settings][border]`, block.settings?.border || '');
      if (!!block.item_nl) formData.append(`blocks[${i}][item_nl]`, block.item_nl);
      if (!!block.set_item_url_nl) formData.append(`blocks[${i}][set_item_url_nl]`, block.set_item_url_nl);
      if (!!block.item_fr) formData.append(`blocks[${i}][item_fr]`, block.item_fr);
      if (!!block.set_item_url_fr) formData.append(`blocks[${i}][set_item_url_fr]`, block.set_item_url_fr);
    });

    fetch_one<News>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      setDividendId(ob.id?.toString());
      openCalculation(ob);
      setSaveLoader(false);
      setDividendSavedSnackOpen(true);
      navigate("/news");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<News>('/nl/v3/objects/fetch_all', {
      object: 'v2_news',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openCalculation(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchTags = () => {
    fetch_all<NewsTag>('/nl/v3/objects/fetch_all', {
      object: 'v2_news_tag',
      fields: ['id', 'name'],
      per_page: 1000,
      page: 0,
      order: "id ASC",
      filter: {}
    }, (obs, complete_data) => {
      setTags(obs);
    }, (z) => {
      
    }, login);
  };

  useEffect(() => {
    fetchCalculation();
  }, []);

  useEffect(() => {
    fetchTags();
  }, []);

  const openCalculation = (ob: News) => {
    setNewsDetail(ob);

    let blocks:NewsBlock[] = [];
    if (!!ob.block_info) ob.block_info.map(b_info => {
      let new_block:NewsBlock = b_info;
      blocks.push(new_block);
    });
    setBlocks(blocks);
    if (!!ob.extra_data?.languages) setLanguageEnabled([...ob.extra_data?.languages.map(ii => parseInt(ii.toString(), 10))]);

    setPreviewLoading(false);
  }

  let langs:[number, string][] = [
    [0, 'NL'],
    [1, 'FR']
  ];

  return (
    <Page sx={{}} title={`News Detail`}>
      <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {setActiveLocale(lc);}}
      />
      <Grid container spacing="8">

        <Grid item xs={4}>
          <StyledSwitch
            label="Is Active"
            value={!!newsDetail?.enabled}
            onChange={(v) => {if (!!newsDetail) setNewsDetail({
              ...newsDetail,
              enabled: !!v,
              ...(!!v ? {is_system: false} : {})
            });}}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledSwitch
            label="Archived"
            value={!!newsDetail?.is_hidden}
            onChange={(v) => {if (!!newsDetail) setNewsDetail({
              ...newsDetail,
              is_hidden: !!v,
              ...(!!v ? {is_system: false} : {})
            });}}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            label="Internal name"
            value={newsDetail?.name || ''}
            id="name"
            onChange={(v) => {if (!!newsDetail) setNewsDetail({
              ...newsDetail,
              name: v});
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <StyledSelect
            label="Category"
            mainSx={{
              "label:not(.MuiInputLabel-shrink)": {
                top: '-8px'
              }
            }}
            value={newsDetail?.tag_id?.toString() || ''}
            id='gender'
            onChange={(v) => {if (!!newsDetail) setNewsDetail({...newsDetail, tag_id: parseInt(v, 10)});}}
            list={tags.sort((a, b) => (a.name?.nl || '') > (b.name?.nl || '') ? 1 : -1).map(tag => {return {id: tag.id || 0, name: tag.name?.nl || ''};})}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            label="Weight"
            value={newsDetail?.weight?.toString() || ''}
            id="weight"
            onChange={(v) => {if (!!newsDetail) setNewsDetail({
              ...newsDetail,
              weight: parseInt(v, 10)});
            }}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          
        </Grid>

        <Grid item container xs={6} spacing="4">
          {activeLocale === 'nl' && <Grid item xs={12}>
            <ImagePicker existing={newsDetail?.get_item_nl} item={item} itemUrl={itemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setItem(_item);
              setItemUrl(_itemUrl);
            }} />
          </Grid>}
          {activeLocale !== 'nl' && <Grid item xs={12}>
            <ImagePicker existing={newsDetail?.get_item_fr} item={itemFr} itemUrl={itemUrlFr} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setItemFr(_item);
              setItemUrlFr(_itemUrl);
            }} />
          </Grid>}
        </Grid>

        <Grid item container xs={6} spacing="4">
          <Grid item xs={4}>
            <Typography sx={{marginTop: 0.5}}>Enabled for languages</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          {langs.map(v => <>
            <Grid item xs={2}>
              <StyledButton
                label={v[1]}
                contained={languageEnabled.indexOf(v[0]) > -1}
                secondary={languageEnabled.indexOf(v[0]) < 0}
                id='languageEnabled'
                onClick={(vv) => {
                  if (languageEnabled.indexOf(v[0]) > -1) {
                    let old_arr = [...languageEnabled];
                    old_arr.splice(old_arr.indexOf(v[0]), 1);
                    setLanguageEnabled([...old_arr]);
                  } else {
                    setLanguageEnabled([
                      ...languageEnabled,
                      v[0]
                    ]);
                  }
                }}
                sx={{width: '100%'}}
              />
            </Grid>
          </>)}
          <Grid item xs={12}>
            <StyledTextFieldTransl
              label="Subject"
              activeLocale={activeLocale}
              value={{nl: newsDetail?.subject?.nl || '', fr: newsDetail?.subject?.fr || ''}}
              id="subject"
              onChange={(v) => {if (!!newsDetail) setNewsDetail({
                ...newsDetail,
                subject: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextFieldTransl
              label="Teaser"
              activeLocale={activeLocale}
              value={{nl: newsDetail?.teaser?.nl || '', fr: newsDetail?.teaser?.fr || ''}}
              id="teaser"
              onChange={(v) => {if (!!newsDetail) setNewsDetail({
                ...newsDetail,
                teaser: v});
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item container xs={6} spacing="4">
          <Grid item xs={12}>
            <StyledEditor
              label="Hero Intro (new website)"
              simple
              activeLocale={activeLocale}
              value={{nl: newsDetail?.nw_intro?.nl || '', fr: newsDetail?.nw_intro?.fr || ''}}
              onChange={(v) => {if (!!newsDetail) setNewsDetail({
                ...newsDetail,
                nw_intro: v});
              }}
            />
          </Grid>
        </Grid>

        {!movingMode && <Grid item container xs={12} sx={{display: 'contents'}}>
          {/* <BlockTags /> */}
          <BlockPicker
            onClick={(block_type:string) => {
              let new_block:NewsBlock = {
                type: block_type
              };
              setBlocks([
                ...blocks,
                new_block
              ]);
              setPreviewLoading(false);
            }}
          />
        </Grid>}

        <Grid item xs={12}>
          <Box sx={{
            width: "calc(100% - 640px)",
            display: 'inline-block'
          }}>
            {blocks.map((block, i) => <>
              <BlockTranslatableSettingsBase
                isFirst={i === 0}
                isLast={i === (blocks.length - 1)}
                forceClose={movingMode}
                setNewPosition={(change) => {
                  let old_blocks = [...blocks];
                  old_blocks.splice(i+change, 0, old_blocks.splice(i, 1)[0]);
                  setBlocks(old_blocks);
                }}
                deleteFromList={() => {
                  let old_blocks = [...blocks];
                  old_blocks.splice(i, 1);
                  setBlocks(old_blocks);
                }}
                setActive={(ac) => {setActiveHover(ac ? i : undefined);}}
                activeLocale={activeLocale}
                block={block}
                setBlock={(eb:NewsBlock) => {
                  let new_blocks:NewsBlock[] = [];
                  blocks.map((bbb, iii) => {
                    if (iii !== i) new_blocks.push(bbb);
                    if (iii === i) new_blocks.push(eb);
                  });
                  setBlocks(new_blocks);
                }}
              />
            </>)}
          </Box>
          <Box sx={{
            width: "600px",
            display: 'inline-block',
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            padding: 1, 
            float: 'right',
            borderRadius: 0.5
          }}>
            {!!previewLoading && <CircularProgress sx={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />}
            {!previewLoading && <Grid container sx={{
              width: '600px',
              "& div": {
                "& h3": {
                  marginTop: '10px',
                  marginBottom: '10px',
                  color: '#105156',
                  fontSize: '16px'
                },
                "& p": {
                  marginTop: '10px',
                  marginBottom: '10px',
                  color: '#212933',
                  fontSize: '14px'
                },
                "& ul, & ol": {
                  color: '#212933',
                  fontSize: '14px'
                },
                "& a": {
                  color: '#5cbf8d !important',
                  textDecoration: 'none !important'
                }
              }
            }}>
              {blocks.map((block, i) => <>
                <BlockPublicBase block={block} marked={activeHover === i} activeLocale={activeLocale} />
              </>)}
            </Grid>}
          </Box>
        </Grid>
        
        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!movingMode && <>
            {!!loader && <CircularProgress />}
            {!loader && <StyledButton
              label="Start manage mode"
              id='start_mm'
              // contained
              onClick={(v) => {
                setMovingMode(true);
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Cancel"
              id='cancel'
              // contained
              onClick={(v) => {
                navigate("/news");
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Save"
              id='save'
              contained
              onClick={(v) => {
                saveSetting();
              }}
              sx={{marginLeft: 1}}
            />}
          </>}
          {!!movingMode && <>
            <StyledButton
              label="Stop manage mode"
              id='cancel_mm'
              // contained
              onClick={(v) => {
                setMovingMode(false);
              }}
              sx={{marginLeft: 1}}
            />
          </>}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
