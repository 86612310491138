import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
// import { useNavigate } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

import {
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  StyledTextField
} from '../../components/interface';

import { Office } from '../../models/Office';

const { DateTime } = require("luxon");

type OfficeOfficesProps = {};

export const OfficeOffices: FunctionComponent<OfficeOfficesProps> = () => {
  // const navigate = useNavigate();

  const login = useAtomValue(loginAtom);
  // const [login, setLogin] = useAtom(loginAtom);
  const [objects, setObjects] = useState<Office[]>([]);
  const [objectsCount, setObjectsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id ASC");
  const [importData, setImportData] = useState<string>("");

  const [filterEnabled, setFilterEnabled] = useState(['1']);
  const [filterHasNumber, setFilterHasNumber] = useState(['0', '1']);
  const [filterCatchAll, setFilterCatchAll] = useState('');

  useEffect(() => {
    onSearch(filterCatchAll);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, filterEnabled, filterHasNumber, filterCatchAll]);

  const doSave = () => {
    fetch_one<Office>('/nl/v3/objects/custom_action', {
      object: 'office',
      class_action: 'custom_api_import',
      handler_id: login?.id,
      import_data: importData
    }, (obs, complete_data) => {
      setShowEdit(false);
      onSearch('');
    }, (z) => {

    }, login);
  };

  const onSearch = (s: string) => {
    setLoader(true);
    fetch_all<Office>('/nl/v3/objects/fetch_all', {
      object: 'office',
      fields: ['id', 'enabled', 'office_number', 'mandate_name', 'office_holder', 'phone', 'last_login', 'office_accounts|count', 'office_logs|count'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          has_number: filterHasNumber,
          enabled: filterEnabled
        }
      }
    }, (obs, complete_data) => {
      setObjects(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setObjectsCount(count);

      setLoader(false);
    }, (z) => {

    }, login);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'office_number', headerName: '#', width: 80 },
    { field: 'mandate_name', headerName: 'Mandate Name', width: 250 },
    { field: 'office_holder', headerName: 'Office Holder', width: 250 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'last_login', headerName: 'Last Login', width: 150, valueFormatter: params => !!params?.value ? DateTime.fromISO(params?.value).toFormat("f") : '-' }
  ];

  return (
    <Page sx={{}} title="Offices" helper="offices" actions={<>
      <StyledButton
        label="Create new"
        id='create_new'
        contained
        onClick={() => {
          setShowEdit(true);
        }}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {!!showEdit && <>
        <Grid container sx={{position: 'relative', marginBottom: 2}}>
          <Grid item xs={12}>
            <CloseIcon sx={{position: 'absolute', top: 0, right: 0, cursor: 'pointer', zIndex: 1000}} onClick={() => {setShowEdit(false);}} />
            <StyledTextField
              label="Paste file contents here, without headers"
              value={importData}
              id='import_data'
              onChange={(v) => {
                setImportData(v);
              }}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              label="Save"
              id='save'
              contained
              onClick={() => {
                doSave();
              }}
              sx={{marginRight: 3}}
              startIcon={<AddCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </>}

      <SearchBox
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        officeSearch={(advanced: {has_number?:string[], enabled?:string[], catch_all: string}) => {
          setFilterHasNumber(advanced.has_number || []);
          setFilterEnabled(advanced.enabled || []);
          setFilterCatchAll(advanced.catch_all || '');
          setPage(0);
        }}
        memo="offices"
      />
      <DataGrid
        action_count={3}
        actions={(params) => {
          return (<>
            <StyledIconButton title="Edit" color="primary" path={`/office/${params.row.id}`}>
              <VisibilityIcon />
            </StyledIconButton>
            {(params.row.office_accounts_count || 0) > 0 && <StyledIconButton title="Office Accounts" color="primary" path={`/office_accounts/${params.row.id}`}>
              <GroupIcon />
            </StyledIconButton>}
            {(params.row.office_logs_count || 0) > 0 && <StyledIconButton title="Office Logs" color="primary" path={`/office_logs/${params.row.id}`}>
              <DescriptionIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={objectsCount}
        objects={(objects || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
