import React, { FunctionComponent } from 'react';

import {
  Box,
  Grid
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { BenefitBlock } from '../../../models/BenefitBlock';
import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicTitleText2ColProps = {
  block: EmailBlock | BenefitBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

export const BlockPublicTitleText2Col: FunctionComponent<BlockPublicTitleText2ColProps> = ({block, marked, activeLocale}) => {
  let string_value = block.string_value;
  if (typeof(string_value) !== 'string') string_value = (activeLocale === 'nl' ? string_value?.nl : string_value?.fr);
  let title_value = block.title_value;
  if (typeof(title_value) !== 'string') title_value = (activeLocale === 'nl' ? title_value?.nl : title_value?.fr);

  let string_value_right = block.string_value_right;
  if (typeof(string_value_right) !== 'string') string_value_right = (activeLocale === 'nl' ? string_value_right?.nl : string_value_right?.fr);
  let title_value_right = block.title_value_right;
  if (typeof(title_value_right) !== 'string') title_value_right = (activeLocale === 'nl' ? title_value_right?.nl : title_value_right?.fr);

  return ( <Grid container item xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 2,
    paddingBottom: 2,
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    <Grid item xs={6} sx={{paddingRight: 0.5}}>
      <Box sx={{backgroundColor: "#E1E8DF", borderRadius: '10px', padding: 2, height: 'calc(100% - 20px)'}}>
        {!!title_value && <Typography variant="h3" html sx={{textTransform: 'uppercase', fontWeight: 'bold', color: "#185459", fontSize: '20px !important'}}>{title_value}</Typography>}
        {!title_value && <Typography variant="h3" sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Title goes here...</Typography>}

        {!!string_value && <Typography nw_text html>{string_value}</Typography>}
        {!string_value && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
      </Box>
    </Grid>
    <Grid item xs={6} sx={{paddingLeft: 0.5}}>
      <Box sx={{backgroundColor: "#E1E8DF", borderRadius: '10px', padding: 2, height: 'calc(100% - 20px)'}}>
        {!!title_value_right && <Typography variant="h3" html sx={{textTransform: 'uppercase', fontWeight: 'bold', color: "#185459", fontSize: '20px !important'}}>{title_value_right}</Typography>}
        {!title_value_right && <Typography variant="h3" sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Title goes here...</Typography>}

        {!!string_value_right && <Typography nw_text html>{string_value_right}</Typography>}
        {!string_value_right && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
      </Box>
    </Grid>
  </Grid>);
};
