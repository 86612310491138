import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import {
  Grid
} from '@mui/material';

import { SearchField } from './SearchField';
import { StyledDateField } from '../StyledDateField';
import { VennNr } from '../VennNr';
import { searchAtom } from '../../../lib/search';

type SearchFieldsProps = {
  visible: boolean;
  generator_fields: ("padding" | "name" | "email" | "birthdate" | "vennr" | "address" | "zicht_account" | 'open')[];
  values?: any;
  doSearch: (z:{
    searchBirthdate:string,
    searchEmail:string,
    searchName:string,
    searchNumber:string,
    searchStreet:string,
    searchVennootNumber:string,
    searchZipCity:string,
    searchZichtAccount:string,
    searchCatchAll:string
  }) => void;
  setEnterPressed?: (v:boolean) => void;
  memo?: 'office_logs' | 'office_accounts' | 'offices' | 'email_templates' | 'users' | 'transactions' | 'faqs' | 'frontpagers' | 'security_questions' | 'statics' | 'custom201s' | 'deaths' | 'exits' | 'shares' | 'dividend_calculations' | 'dividend_pains' | 'banners' | 'user_changes' | 'benefits' | 'news' | 'stock_transfers' | 'office_statics';
};

export const SearchFields: FunctionComponent<SearchFieldsProps> = ({memo, visible, values, generator_fields, doSearch, setEnterPressed}) => {

  const [memoSearch, setMemoSearch] = useAtom(searchAtom);

  const [enterPressed, internal_setEnterPressed] = useState(false);

  const [searchBirthdate, setSearchBirthdate] = useState(!!values && !!values.birth_date ? values.birth_date : '');
  const [searchEmail, setSearchEmail] = useState(!!values && !!values.email ? values.email : '');
  const [searchName, setSearchName] = useState(!!values && !!values.name ? values.name : '');
  const [searchNumber, setSearchNumber] = useState(!!values && !!values.number ? values.number : '');
  const [searchStreet, setSearchStreet] = useState(!!values && !!values.street ? values.street : '');
  const [searchVennootNumber, setSearchVennootNumber] = useState(!!values && !!values.vennoot_number ? values.vennoot_number : '');
  const [searchZipCity, setSearchZipCity] = useState(!!values && !!values.zip ? values.zip : '');
  const [searchZichtAccount, setSearchZichtAccount] = useState(!!values && !!values.zicht_account ? values.zicht_account : '');
  const [searchCatchAll, setSearchCatchAll] = useState(!!values && !!values.catch_all ? values.catch_all : '');

  useEffect(() => {
    doSearch({
      searchBirthdate: searchBirthdate,
      searchEmail: searchEmail,
      searchName: searchName,
      searchNumber: searchNumber,
      searchStreet: searchStreet,
      searchVennootNumber: searchVennootNumber,
      searchZipCity: searchZipCity,
      searchZichtAccount: searchZichtAccount,
      searchCatchAll: searchCatchAll
    });
  }, [searchBirthdate, searchEmail, searchName, searchNumber, searchStreet, searchVennootNumber, searchZipCity, searchZichtAccount, searchCatchAll]);

  useEffect(() => {
    if (!!enterPressed && !!setEnterPressed) {
      setEnterPressed(true);
    }
  }, [enterPressed]);

  useEffect(() => {
    // console.log(values);
    if (!!values) {
      if (!!values.birth_date || values.force) setSearchBirthdate(values.birth_date || '');
      if (!!values.street || values.force) setSearchStreet(values.street || '');
      if (!!values.number || values.force) setSearchNumber(values.number || '');
      if (!!values.zip || values.force) setSearchZipCity(values.zip || '');
      if (!!values.email || values.force) setSearchEmail(values.email || '');
      if (!!values.name || values.force) setSearchName(values.name || '');
      if (!!values.vennoot_number || values.force) setSearchVennootNumber(values.vennoot_number || '');
      if (!!values.zicht_account || values.force) setSearchZichtAccount(values.zicht_account || '');
      if (!!values.catch_all || values.force) setSearchCatchAll(values.catch_all || '');
    }
    // console.log(values);
  }, [values]);

  useEffect(() => {
    if (!!memo && !!memoSearch[memo]) {
      setSearchBirthdate(memoSearch[memo].birth_date);
      setSearchStreet(memoSearch[memo].street);
      setSearchNumber(memoSearch[memo].number);
      setSearchZipCity(memoSearch[memo].zip);
      setSearchEmail(memoSearch[memo].email);
      setSearchName(memoSearch[memo].name);
      setSearchVennootNumber(memoSearch[memo].vennoot_number);
    }
  }, [memoSearch]);

  return (visible ? <>
    {generator_fields.map(field => <>
      {field === "padding" && <Grid item xs={3}>&nbsp;</Grid>}
      {field === "name" && <Grid item xs={3} sx={{paddingRight: 1}}>
        <SearchField name="Name" value={searchName} setValue={(v) => {
          setSearchName(v);
        }} setEnterPressed={(b) => {internal_setEnterPressed(b);}} />
      </Grid>}
      {field === "email" && <Grid item xs={3} sx={{paddingRight: 1}}>
        <SearchField name="Email" value={searchEmail} setValue={(v) => {
          setSearchEmail(v);
        }} setEnterPressed={(b) => {internal_setEnterPressed(b);}} />
      </Grid>}
      {field === "open" && <Grid item xs={3} sx={{paddingRight: 1}}>
        <SearchField name="Open" value={searchCatchAll} setValue={(v) => {
          setSearchCatchAll(v);
        }} setEnterPressed={(b) => {internal_setEnterPressed(b);}} />
      </Grid>}
      {field === "zicht_account" && <Grid item xs={3} sx={{paddingRight: 1}}>
        <SearchField name="ZichtAccount" value={searchZichtAccount} setValue={(v) => {
          setSearchZichtAccount(v);
        }} setEnterPressed={(b) => {internal_setEnterPressed(b);}} />
      </Grid>}
      {field === "birthdate" && <Grid item xs={3} sx={{paddingRight: 1}}>
        <StyledDateField
          label="Birthdate"
          value={searchBirthdate}
          id='search_birthdate'
          onChange={(v) => {
            setSearchBirthdate(v.toString());
          }}
          sx={{width: '100%', "input": {backgroundColor: '#a9c2c0', padding: 1}}}
          setEnterPressed={(b) => {internal_setEnterPressed(b);}}
        />
      </Grid>}
      {field === "vennr" && <Grid item xs={3} sx={{paddingRight: 1}}>
        <VennNr
          label="Vennotennummer"
          value={searchVennootNumber}
          id='search_vennoot_number'
          onChange={(v) => {
            setSearchVennootNumber(v.toString());
          }}
          sx={{width: '100%', "input": {backgroundColor: '#a9c2c0', padding: 1}}}
          setEnterPressed={(b) => {internal_setEnterPressed(b);}}
        />
      </Grid>}
      {field === "address" && <>
        <Grid item xs={2} sx={{paddingRight: 1}}>
          <SearchField name="Street" value={searchStreet} setValue={(v) => {
            setSearchStreet(v);
          }} setEnterPressed={(b) => {internal_setEnterPressed(b);}} />
        </Grid>
        <Grid item xs={2} sx={{paddingRight: 1}}>
          <SearchField name="Number" value={searchNumber} setValue={(v) => {
            setSearchNumber(v);
          }} setEnterPressed={(b) => {internal_setEnterPressed(b);}} />
        </Grid>
        <Grid item xs={2} sx={{paddingRight: 1}}>
          <SearchField name="Zip/City" value={searchZipCity} setValue={(v) => {
            setSearchZipCity(v);
          }} setEnterPressed={(b) => {internal_setEnterPressed(b);}} />
        </Grid>
      </>}
    </>)}
  </> : <></>);
}